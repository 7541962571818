import { ChartTitle as Title, ExhibitTitle } from "./styled";

function ChartTitle({ data, minYear, maxYear, ...props }) {
  const chartTitle = (datasets) => {
    return datasets.map((dataset) => {
      return dataset.label;
    }).join(" & ");
  };

  const getYearRange = () => {
    if(minYear && maxYear){
      return minYear !== maxYear ? `${minYear} to ${maxYear}` : maxYear;
    } else {
      return "";
    }
  }
  
  return (
    <>
      {data.exhibitTitle && (
        <>
        <ExhibitTitle>{data.exhibitTitle}</ExhibitTitle>
        <Title dangerouslySetInnerHTML={{__html: `${chartTitle(data.datasets)} – ${getYearRange()}`}} />
        {/* {data.valueLabel && <ChartValueLabel>{data.valueLabel}</ChartValueLabel>} */}
      </>
      )}
      {data.title && !data.exhibitTitle && (
        <>
          <Title>{`${data.title} – ${getYearRange()}`}</Title>
          {/* {data.valueLabel && <ChartValueLabel>{data.valueLabel}</ChartValueLabel>} */}
        </>
      )}
      {!data.title && !data.exhibitTitle && (
        <>
        <Title dangerouslySetInnerHTML={{__html: `${chartTitle(data.datasets)} – ${getYearRange()}`}} />
        </>
      )}
    </>
  );
}

export default ChartTitle;
