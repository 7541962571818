export const dateFormat = "YYYY-MM-DD";
export const themeColors = {
    PRIMARY_COLOR: "#d55e27",
    SECONDARY_COLOR: "#141E3C"
}

export const chartViews = {
    BAR_CHART: "Bar",
    LINE_CHART: "Line",
    SCATTER_CHART: "Scatter"
}

export const tabViews = {
    CASE_STUDIES: "case studies",
    FREE_FORM: "free form"
}

export const chartModes = [
    {
        label: "Value",
        value: "value"
    },
    {
        label: "Rank",
        value: "rank"
    },
]

export const exhibitAxisLabels = {
    "A2": {
        P464: "y1",
        P465: "y",
        minDate: "2020-01-02",
        maxDate: "2020-05-28",
        left: "Yield Spreads (Whole Percent)",
        right: "S&P 500 Index",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    },
    "A3": {
        P472: "y",
        minDate: "2019-01-22",
        maxDate: "2022-10-28",
        left: "Index Jan 2006 = 100",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    },
    "A5": {
        P458: "y",
        minDate: "2003-01-01",
        maxDate: "2021-01-01",
        left: "Chained 2012 Dollars",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    }, 
    "A6": {
        P486: "y1",
        P470: "y",
        P471: "y",
        minDate: "2020-01-01",
        left: "Percentage Change Since Onset of Crisis",
        right: "COVID-Related Deaths per Month",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    },
    "A7": {
        P474:  "y",
        P476: "y1",
        left: "Democratic Index",
        right: "Gini Coefficient",
        defaultChartView: "bar chart",
        relatedArticles: [
            {
                title: "Inequality, Pluralism, Rule of Law, Civic Participation, Consent, Elections, Media Freedom",
                url: "#"
            }
        ]
    },
    "B1": {
        P464: "y",
        P480: "y1",
        // minDate: "2015-11-19",
        // maxDate: "2015-12-31",
        left: "S&P 500 Index",
        right: "Percent Change",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    },
    "C2": {
        P460: "y",
        left: "Millions of U.S. Dollars",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    },
    "C3": {
        P461: "y",
        left: "Percentage of GDP",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    },
    "C4": {
        P481: "y",
        P482: "y",
        left: "Whole Percent",
        right: "Whole Percent",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    },
    "C5": {
        P468: "y1",
        P469: "y1",
        P461: "y",
        minDate: "1966-12-31",
        maxDate: "2022-04-01",
        left: "Market Yield: % Chg",
        right: "Rate: % Chg",
        relatedArticles: [
            {
                title: "Capital, Money and Credit, Open Markets",
                url: "#"
            }
        ]
    }
}

export const selectStyles = {
    option: (styles) => {
      return {
        ...styles,
        ":active": {
          ...styles[":active"],
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        color: "#141E3C",
        backgroundColor: "#FBFCFF",
        borderRadius: 4,
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: "#141E3C",
        backgroundColor: "#FBFCFF",
      };
    },
  };