import { useState } from "react";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import ScatterChart from "./ScatterChart";
import { Tooltip as Popover } from "./Tooltip";
import Checkbox from "./Checkbox";
import ChartTitle from "./ChartTitle";
import ChartDescription from "./ChartDescription";
import { chartModes, chartViews, tabViews } from "./constants";
import { getShareURL } from "./utils";
import {
  Button,
  ButtonBar,
  Chart,
  Field,
  IndexValuesBtn,
  ResultsContainer,
  SelectInput,
  Toolbar,
  TooltipContent,
  TooltipTrigger,
  ChartDataToggle,
} from "./styled";

function Results({
  results,
  countryOptions,
  onReset,
  chartView,
  onChartView,
  onDateChange,
  mode,
  tabView,
  toggleSidebar,
  isSidebarOpen,
  onMinYearSelection,
  onMaxYearSelection,
}) {
  const [displayIndexValues, setDisplayIndexValues] = useState(false);
  const [chartMode, setChartMode] = useState(chartModes[0]);
  const [minYear, setMinYear] = useState();
  const [maxYear, setMaxYear] = useState();

  const currentChartView =
    countryOptions.length === 1 ? chartViews.LINE_CHART : chartView;
  const datasetIds = results.datasets
    .map((x) => {
      return x.value;
    })
    .join(",");

  const isDisabled = (type) => {
    return (
      (type === chartViews.BAR_CHART || type === chartViews.SCATTER_CHART) &&
      countryOptions.length === 1
    );
  };

  const hasChart = (type) => {
    return (
      mode === tabViews.FREE_FORM ||
      results.exhibit?.supportedChartViews.includes(type)
    );
  };

  const handleChange = (e) => {
    setDisplayIndexValues(e.target.checked);
  };

  const handleChartModeSelection = (selectedOptions) => {
    setChartMode(selectedOptions);
  };

  const handleMinYearSelection = (year) => {
    setMinYear(year);
    onMinYearSelection(year);
  }

  const handleMaxYearSelection = (year) => {
    setMaxYear(year);
    onMaxYearSelection(year);
  }

  const collator = new Intl.Collator("en", {
    numeric: true,
    sensitivity: "base",
  });

  const datasets = results.datasets.sort((a, b) => {
    const datasetA = results.exhibit?.datasets.find((x) => {
      return x.dataset === a.value;
    });
    const datasetB = results.exhibit?.datasets.find((x) => {
      return x.dataset === b.value;
    });
    return collator.compare(datasetA.axis, datasetB.axis);
  });

  const share = () => {
    console.log(window.parent, document.referrer);
    navigator.clipboard.writeText(getShareURL());
  };

  const chart = {
    descriptions: datasets.map((dataset) => dataset.description),
  };

  return (
    <ResultsContainer isSidebarOpen={isSidebarOpen}>
      <ChartTitle data={results} minYear={minYear} maxYear={maxYear} />
      {!results.exhibit && (
        <ChartDescription chart={chart} datasets={datasets} mode={mode} />
      )}
      {/* <Button onClick={toggleSidebar} active={true}>
          <span>Options</span>
          <img src="menu.svg" alt="" />
        </Button> */}
      <Toolbar>
        <ButtonBar>
          {/* <ButtonBarLabel>Chart Type:</ButtonBarLabel> */}
          {hasChart(chartViews.LINE_CHART) && (
            <Button
              onClick={() => onChartView(chartViews.LINE_CHART)}
              aria-label="Line Chart"
              active={currentChartView === chartViews.LINE_CHART}
              disabled={isDisabled(chartViews.LINE_CHART)}
            >
              <svg viewBox="0 0 512 512">
                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                  <g
                    transform="translate(0.013000, 0.009000)"
                    fillRule="nonzero"
                  >
                    <polygon
                      id="Path"
                      points="39.248 472.733 39.248 0 0 0 0 511.981 511.974 511.981 511.974 472.733"
                    ></polygon>
                    <polygon
                      id="Path"
                      points="251.971 385.581 435.869 175.181 511.974 262.147 511.974 174.056 435.869 87.122 251.971 297.489 170.992 204.992 78.496 204.992 78.496 263.022 144.712 263.022"
                    ></polygon>
                  </g>
                </g>
              </svg>
              <span>Line</span>
            </Button>
          )}
          {hasChart(chartViews.BAR_CHART) && (
            <Button
              onClick={() => onChartView(chartViews.BAR_CHART)}
              aria-label="Bar Chart"
              active={currentChartView === chartViews.BAR_CHART}
              disabled={isDisabled(chartViews.BAR_CHART)}
            >
              <svg viewBox="0 0 96 78">
                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                  <g
                    transform="translate(0.500000, 0.666000)"
                    fillRule="nonzero"
                  >
                    <rect
                      id="Rectangle"
                      x="0"
                      y="44.667"
                      width="17"
                      height="32"
                    ></rect>
                    <rect
                      id="Rectangle"
                      x="26"
                      y="22.834"
                      width="17"
                      height="53.833"
                    ></rect>
                    <rect
                      id="Rectangle"
                      x="52"
                      y="34"
                      width="17"
                      height="42.667"
                    ></rect>
                    <rect
                      id="Rectangle"
                      x="78"
                      y="0"
                      width="17"
                      height="76.668"
                    ></rect>
                  </g>
                </g>
              </svg>
              <span>Bar</span>
            </Button>
          )}
          {hasChart(chartViews.SCATTER_CHART) && !results.exhibit && (
            <Button
              onClick={() => onChartView(chartViews.SCATTER_CHART)}
              aria-label="Scatter Chart"
              active={currentChartView === chartViews.SCATTER_CHART}
              disabled={isDisabled(chartViews.SCATTER_CHART)}
            >
              <svg viewBox="0 0 80 80">
                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                  <g fillRule="nonzero">
                    <polygon
                      id="Path"
                      points="6 0.0411987 0 0.0411987 0 79.9588013 0.0823975 79.9588013 0.0823975 80 80 80 80 74 6 74"
                    ></polygon>
                    <circle
                      id="Oval"
                      cx="23.1591797"
                      cy="56.8408203"
                      r="7.5795898"
                    ></circle>
                    <circle
                      id="Oval"
                      cx="39.5795898"
                      cy="30.4204102"
                      r="7.5795898"
                    ></circle>
                    <circle id="Oval" cx="56" cy="48" r="7.5795898"></circle>
                    <circle
                      id="Oval"
                      cx="72.4204102"
                      cy="7.5795898"
                      r="7.5795898"
                    ></circle>
                  </g>
                </g>
              </svg>
              <span>Scatter</span>
            </Button>
          )}{" "}
          {!results.exhibit && currentChartView !== chartViews.SCATTER_CHART && (
            <Field layout="horizontal">
              <SelectInput
                id="topics"
                value={chartMode}
                options={chartModes}
                onChange={handleChartModeSelection}
                getOptionLabel={(option) => `View as: ${option.value.charAt(0).toUpperCase() + option.value.slice(1)}`}
                styles={{
                  control: (baseStyles, state) => {
                    return ({
                    ...baseStyles,
                    borderColor: 'var(--blue)',
                    "&:hover": {
                    borderHoverColor: 'var(--blue)'
                    },
                    borderWidth: "0.1rem",
                    fontWeight: "700",
                    fontSize: "0.875rem",
                    minHeight: "2rem"
                  })},
                  dropdownIndicator: base => ({
                    ...base,
                    color: 'var(--blue)',
                    padding: "0 0.5rem"
                  })
                }}
              />
            </Field>
          )}
          {chartMode.value !== "rank" && currentChartView === chartViews.LINE_CHART && (
            <IndexValuesBtn>
              <ChartDataToggle
                checked={displayIndexValues}
                onChange={handleChange}
                label="Show indexed values"
                as={Checkbox}
              />
              <Popover>
                <TooltipTrigger asChild={true}>
                  <img src="/info-icon.svg" alt="" />
                  <span>View Definition</span>
                </TooltipTrigger>
                <TooltipContent className="Tooltip">
                  Show indexed dataset values for common years to depict
                  relative change over time
                </TooltipContent>
              </Popover>
            </IndexValuesBtn>
          )}
        </ButtonBar>
        <ButtonBar>
          <Button
            size="icon"
            href={`https://api.demcapanalytics.com/download/${datasetIds}/`}
            target="_blank"
            aria-label="Download Data"
            as="a"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            <span>Download Data</span>
          </Button>
          <Button size="icon" onClick={share}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
              />
            </svg>
            <span>Copy Link</span>
          </Button>
        </ButtonBar>
      </Toolbar>
      <Chart>
        {currentChartView === chartViews.BAR_CHART && (
          <BarChart
            data={results}
            mode={mode}
            onDateChange={onDateChange}
            onMinYearSelection={handleMinYearSelection}
            onMaxYearSelection={handleMaxYearSelection}
            chartMode={chartMode}
          />
        )}
        {currentChartView === chartViews.LINE_CHART && (
          <LineChart
            data={results}
            mode={mode}
            onDateChange={onDateChange}
            onMinYearSelection={handleMinYearSelection}
            onMaxYearSelection={handleMaxYearSelection}
            displayIndexValues={displayIndexValues}
            chartMode={chartMode}
          />
        )}
        {currentChartView === chartViews.SCATTER_CHART && (
          <ScatterChart
            data={results}
            mode={mode}
            onMinYearSelection={handleMinYearSelection}
            onMaxYearSelection={handleMaxYearSelection}
          />
        )}
      </Chart>
      <ButtonBar align="right"></ButtonBar>
    </ResultsContainer>
  );
}

export default Results;
