import React, { useEffect, useState, useRef } from "react";
import chroma from "chroma-js";
import { Chart as ChartJS, LinearScale, PointElement, Tooltip } from "chart.js";
import { Bubble } from "react-chartjs-2";
import {
  ChartSource,
  ChartFooter,
  ChartFooterHeader,
  ChartFooterData,
  ChartWrapper,
  ChartDataToggle,
} from "./styled";
import Checkbox from "./Checkbox";
import Stats from "./Stats";
import ChartDescription from "./ChartDescription";
import ChartLegend from "./ChartLegend";
import YearNavigation from "./YearNavigation";
import {
  buildYearOptions,
  reverse,
  getAxisTitle,
  getStepSize,
  collator
} from "./utils";
import { chartModes, tabViews } from "./constants";
import { Corsair } from "./chartPlugins";

ChartJS.register(
  LinearScale, PointElement, 
);

function ScatterChart({
  data,
  mode,
  onMinYearSelection,
  onMaxYearSelection,
  ...props
}) {
  const [charts, setCharts] = useState([]);
  const [chartMode, setChartMode] = useState(chartModes[1]);
  const [yearValues, setYearValues] = useState([0, 0]);
  const [yearOptions, setYearOptions] = useState([]);
  const [displayIndexValues, setDisplayIndexValues] = useState(false);
  const chartRef = useRef(null);

  const handleYearSelection = (values) => {
    if(Array.isArray(values)){
      setYearValues([values[0], values[1]]);
    } else {
      setYearValues([values, values]);
    }
  };

  useEffect(() => {
    onMinYearSelection(yearValues[0]);
    onMaxYearSelection(yearValues[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearValues]);

  const values = data.data;
  const exhibit = data.exhibit;
  const minYear = exhibit?.start;
  const maxYear = exhibit?.end;
  const datasetLabels = data.datasets?.map((x) => x.label);
  const datasets = data.datasets.sort((a, b) => {
    const datasetA = exhibit?.datasets.find((x) => {
      return x.dataset === a.value;
    });
    const datasetB = exhibit?.datasets.find((x) => {
      return x.dataset === b.value;
    });
    return collator.compare(datasetA.axis, datasetB.axis);
  });
  let countries = data.countries?.map((x) => x.label);
  countries = [...new Set(countries)];

  let colorRangeSize;
  if (countries.length === 1) {
    colorRangeSize = datasetLabels.length;
  } else {
    colorRangeSize = countries.length;
  }

  const colorRange = chroma
    .scale(["#915cac", "#be3074", "#5cac99"])
    .mode("lch")
    .colors(colorRangeSize);

  useEffect(() => {
    if (!data.datasets) return;
    const overlapOnly = exhibit && exhibit?.id !== "CUSTOM";
    const yearOptions = buildYearOptions(data.data, countries, overlapOnly, minYear, maxYear);
    setYearOptions(yearOptions);
  }, [data]); // eslint-disable-line

  useEffect(() => {
      setYearValues([yearOptions[yearOptions.length - 1]?.value, yearOptions[yearOptions.length - 1]?.value]);
  }, [chartMode, yearOptions]); // eslint-disable-line

  useEffect(() => {
    if (yearValues) {
      generateCharts();
    }
  }, [chartMode, yearValues]); // eslint-disable-line


  useEffect(() => {
    if (mode === tabViews.CASE_STUDIES) {
      setChartMode(chartModes[0]);
    }
  }, [mode]); // eslint-disable-line

  const generateCharts = () => {
    let newCharts = [];
    let groupedData = [];
    const year = yearValues[0];
    let max = 0;
    let minRank = 999;
    let maxRank = 0;

    values.forEach((x) => {
      if (parseInt(x.year) === parseInt(year)) {
        if (parseFloat(x.value) > max) {
          max = x.value;
        }

        if (parseInt(x.rank_value) > maxRank) {
          maxRank = x.rank_value;
        }

        if (parseInt(x.rank_value) < minRank) {
          minRank = x.rank_value;
        }
      }
    });

    let options = {
      responsive: true,
      clip: false,
      plugins: {
        corsair: {
          color: "#888",
        },
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          wrap: true,
          font: {
            size: 20,
            weight: 700,
          },
          color: "#000000",
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.raw.label;
              if (mode === tabViews.CASE_STUDIES) {
                label += " -  Value: " + context.raw.rawValue.toLocaleString();
              } else if (chartMode.value === "rank") {
                label +=
                  " -  Rank: " +
                  context.raw.rank +
                  ", Value: " +
                  context.raw.rawValue.toLocaleString();
              } else {
                label +=
                  " -  Value: " +
                  context.raw.rawValue.toLocaleString() +
                  ", Rank: " +
                  context.raw.rank;
              }
              return label;
            },
          },
          position: "average",
          intersect: false,
          //mode: "index",
          titleFont: {
            size: 16,
            weight: 700,
          },
          bodyFont: {
            size: 13,
            weight: 700,
           
          },
          padding: 10,
          boxPadding: 6,
          cornerRadius: 2,
          borderColor: "#ddd",
          borderWidth: 1,
          titleColor: "#000000",
          titleMarginBottom: 10,
          bodyColor: "#000000",
          bodySpacing: 8,
          backgroundColor: "rgba(255,255,255,1)"
        },
      },
      layout: {
        padding: {
          right: 50,
        },
      },
      scales: {
        y: {
          reverse: datasets[0].valueOrder === "ASC",
          barThickness: 10,
          maxBarThickness: 10,
          ticks: {
            color: "#000000",
            font: {
              weight: 700,
              size: 12,
            },
          },
          title: {
            display: true,
            text: getAxisTitle(null, datasets, "y") || "Value",
            font: {
              size: 14,
              weight: 700,
            },
            color: "#000000",
          },
        },
        x: {
          reverse: false,
          ticks: {
            color: "#000000",
            font: {
              weight: 700,
              size: 14,
            },
            callback: function (value) {
              if (Math.floor(value) === value) {
                return value;
              }
            },
          },
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: "Rank",
            font: {
              size: 14,
              weight: 700,
            },
            color: "#000000",
          },
        },
      },
    };

    options.scales.y.ticks.callback = function (dataLabel, index) {
      if (Math.floor(dataLabel) === dataLabel && dataLabel > 0) {
        return dataLabel;
      }
    };

    options.scales.x.ticks.callback = function (dataLabel, index) {
      if (Math.floor(dataLabel) === dataLabel && dataLabel > 0) {
        return reverse(dataLabel);
      }
    };

    options.scales.x.min = reverse(maxRank) - 1;
    options.scales.x.max = reverse(minRank);

    let minValue;
    let maxValue;

    countries.forEach((x, idx) => {
      const data = values
        .filter((y) => {
          return y.country === x && parseInt(y.year) === parseInt(year);
        })
        .map((z) => {
          const rank_value = reverse(z.rank_value);
          return {
            x: rank_value,
            y: parseFloat(z.value),
            r: (z.value / max) * 100 * 0.2,
            label: z.country,
            rank: parseFloat(z["rank_value"]),
            rawValue: parseFloat(z["value"]),
            color: "#000",
          };
        });

      groupedData.push({
        label: x,
        data,
        // animationBorderColor: chroma(colorRange[idx]).brighten().saturate(4),
        // hoverBorderColor: chroma(colorRange[idx]).brighten().saturate(4),
        borderColor: colorRange[idx],
        border: 0,
        legendColor: colorRange[idx],
        backgroundColor: colorRange[idx],
      });
    });

    const otherCountriesData = values
      .filter((x) => {
        return !countries.includes(x.country) && parseInt(x.year) === parseInt(year);
      })
      .map((x) => {
        const rank_value = reverse(x.rank_value);
        const value = parseFloat(x.value);

        if (value > maxValue || !maxValue) {
          maxValue = value;
        }

        if (value < minValue || !minValue) {
          minValue = value;
        }

        return {
          x: rank_value,
          y: value,
          r: (x.value / max) * 100 * 0.15,
          label: x.country,
          rank: parseFloat(x["rank_value"]),
          rawValue: parseFloat(x["value"]),
        };
      });

    groupedData.push({
      label: "All Countries",
      data: otherCountriesData,
      borderWidth: 0,
      borderColor: "rgba(0, 0, 0, 0.1)",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      legendColor: "rgba(0, 0, 0, 0.1)",
    });

    options.scales.y.ticks.stepSize = getStepSize(minValue, maxValue);

    const chart = {
      datasets: {
        datasets: groupedData,
      },
      //descriptions: datasets.map((dataset) => dataset.description),
      options,
    };

    newCharts.push(chart);
    setCharts(newCharts);
  };

  const getChartOptions = (options) => {
    if (
      displayIndexValues &&
      chartMode.value !== "rank" &&
      datasets.length > 1
    ) {
      const newOptions = JSON.parse(JSON.stringify(options));
      return {
        ...newOptions,
        scales: {
          ...newOptions.scales,
          y: {
            ...newOptions.scales.y,
            title: { ...newOptions.scales.y, title: "" },
          },
          y1: null,
        },
      };
    } else {
      return options;
    }
  };

  const getChartData = (data) => {
    const indexedData = data.datasets.map((dataset) => {
      const newDataset = JSON.parse(JSON.stringify(dataset));
      let indexBaseValue;
      if (
        displayIndexValues &&
        chartMode.value !== "rank" &&
        datasets.length > 1
      ) {
        newDataset.yAxisID = "y";
        newDataset.label = `${dataset.label
          .replace("(right axis)", "")
          .replace("(left axis)", "")} - ${dataset.datasetLabel}`;
      }
      newDataset.data = newDataset.data.map((data) => {
        const dataset = JSON.parse(JSON.stringify(data));
        if (dataset.y > 0 && !indexBaseValue) {
          indexBaseValue = dataset.y;
        }
        if (indexBaseValue) {
          dataset.indexValue = Math.round((dataset.y / indexBaseValue) * 100);
        } else {
          dataset.indexValue = 0;
        }
        if (displayIndexValues && chartMode.value !== "rank") {
          dataset.y = dataset.indexValue;
          // } else if (chartMode.value === "rank") {
          //   dataset.y = reverse(dataset.rank);
        } else {
          dataset.y = dataset.rawValue;
        }
        return dataset;
      });

      return newDataset;
    });

    return { ...data, datasets: indexedData };
  };

  const handleChange = (e) => {
    setDisplayIndexValues(e.target.checked);
  };

  return (
    <>
      {charts.map((x, idx) => (
        <>
          <ChartWrapper>
            {chartMode.value !== "rank" && (
              <ChartDataToggle
                checked={displayIndexValues}
                onChange={handleChange}
                label="Show indexed values"
                as={Checkbox}
              />
            )}
            <Bubble
              ref={chartRef}
              options={getChartOptions(x.options)}
              data={getChartData(x.datasets)}
              plugins={[Tooltip, Corsair]}
            />
          </ChartWrapper>
          <ChartFooter>
            <YearNavigation
              onChange={handleYearSelection}
              value={yearValues}
              min={yearOptions[0]?.value}
              max={yearOptions[yearOptions.length - 1]?.value}
              range={false}
              yearOptions={yearOptions}
            />
            <ChartFooterHeader>
              <ChartLegend
                chart={x}
                data={getChartData(x.datasets)}
                mode={mode}
                countries={countries}
              />
              <Stats
                data={data}
                correlation={true}
                showTitle={false}
                selectedMinYear={yearValues[0]}
                selectedMaxYear={yearValues[1]}
              ></Stats>
            </ChartFooterHeader>
            <ChartFooterData>
              {exhibit && (
                <ChartDescription
                  chart={x}
                  datasets={datasets}
                  mode={mode}
                  isExhibit={exhibit}
                />
              )}
              {data.source && (
                <ChartSource
                  dangerouslySetInnerHTML={{
                    __html: `<b>Sources</b>: ${data.source}`,
                  }}
                />
              )}
            </ChartFooterData>
          </ChartFooter>
        </>
      ))}
    </>
  );
}

export default ScatterChart;
