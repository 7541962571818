function RelatedArticles({ articles, ...props }) {
  return (
    <>
      <h3>Related Articles</h3>
      <ul>
        {articles.map((article, idx) => {
          return (
            <li key={`${idx}-article`}>
              <a href={article.url} target="_blank" rel="noreferrer">
                {article.title}
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default RelatedArticles;
