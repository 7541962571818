import { tabViews } from "./constants";
import { Legend, LegendItem, Swatch } from "./styled";

function ChartLegend({ chart, data, mode, countries, ...props }) {
  return (
    <>
      <Legend $mode={mode}>
        {data.datasets.map((x, idx) => {
          let index = idx;
          if(mode === tabViews.FREE_FORM){
            index = x.index;
          } else {
            index++;
          }
          return (
            <LegendItem key={`${index}-${x.label}`}>
              <Swatch
                color={x.legendColor}
                background={x.legendColor}
                dash={x.borderDash}
                axisIndex={x.axisIndex}
              ></Swatch>
              <span
                dangerouslySetInnerHTML={{
                  __html: x.description
                    ? `${x.label} <sup>${index}</sup>`
                    : x.label,
                }}
              ></span>
            </LegendItem>
          );
        })}
      </Legend>
    </>
  );
}

export default ChartLegend;
