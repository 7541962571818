import { Checkbox } from "./styled";
const CheckboxUI = ({ label, checked, onChange, className, ...props }) => {
  return (
    <Checkbox className={className}>
      <label>
      <input type="checkbox" checked={checked} onChange={onChange} /><span>{label}</span>
      </label>
    </Checkbox>
  );
};
export default CheckboxUI;