export const getCaseStudies = async () => {
  return await fetch("https://api.demcapanalytics.com/get_case_studies").then(
    (response) => {
      return response.json();
    }
  );
  // return await fetch("mocks/case-studies.json").then((response) => {
  //   return response.json();
  // });
};


export const getCountries = async () => {
  return await fetch("https://api.demcapanalytics.com/get_countries/").then(
    (response) => {
      return response.json();
    }
  );
  // return await fetch("mocks/countries.json").then((response) => {
  //   return response.json();
  // });
};

export const getDatasets = async () => {
  return await fetch("https://api.demcapanalytics.com/get_datasets/").then(
    (response) => {
      return response.json();
    }
  );
  // return await fetch("mocks/datasets.json").then((response) => {
  //   return response.json();
  // });
};

export const getYears = async () => {
  return await fetch("https://api.demcapanalytics.com/get_years/").then(
    (response) => {
      return response.json();
    }
  );
  // return await fetch("mocks/years.json").then((response) => {
  //   return response.json();
  // });
};

export const getCategories = async () => {
  return await fetch("https://api.demcapanalytics.com/get_categories/").then(
    (response) => {
      return response.json();
    }
  );
  // return await fetch("mocks/categories.json").then((response) => {
  //   return response.json();
  // });
};

export const getAllResults = async (datasets, years, countries) => {
  const params = new URLSearchParams({
    ...(years && { y: years }),
    ...(countries && { y: countries }),
    s: 99999
  });

  const url = `https://api.demcapanalytics.com/get_data/${datasets}/?${params.toString().replaceAll('%2C', ',')}`;
  return await fetch(url).then(
    (response) => {
      return response.json();
    }
  );
  // return await fetch("mocks/P100_P200.json").then((response) => {
  //   return response.json();
  // });
};


export const getResults = async (datasets, years, countries) => {
  const params = new URLSearchParams({
    ...(years && { y: years }),
    ...(countries && { y: countries }),
    s: 99999
  });

  const url = `https://api.demcapanalytics.com/get_data/${datasets}/?${params.toString().replaceAll('%2C', ',')}`;
  return await fetch(url).then(
    (response) => {
      return response.json();
    }
  );
  // return await fetch("mocks/P100_P200.json").then((response) => {
  //   return response.json();
  // });
};

