import React, { useEffect, useState, useRef, Fragment } from "react";
import chroma from "chroma-js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  ChartSource,
  ChartFooter,
  ChartFooterHeader,
  ChartFooterData,
  ChartWrapper,
  YearNavigationStyled as YearNavigation
} from "./styled";
import Stats from "./Stats";
import ChartDescription from "./ChartDescription";
import ChartLegend from "./ChartLegend";
import {
  buildYearOptions,
  isBetween,
  reverse,
  formatDate,
  filterDatasetData,
  getAxisTitle,
  getMinSharedYear,
  findValueByCountryAndYear,
  getStepSize,
  collator,
} from "./utils";
import { Corsair } from "./chartPlugins";
import { tabViews, themeColors } from "./constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

function LineChart({
  data,
  mode,
  onMinYearSelection,
  onMaxYearSelection,
  displayIndexValues,
  chartMode,
  ...props
}) {
  const [charts, setCharts] = useState([]);
  const [yearValues, setYearValues] = useState([0, 0]);
  const [yearOptions, setYearOptions] = useState([]);
  const chartRef = useRef(null);

  const handleYearSelection = (values) => {
    setYearValues([values[0], values[1]]);
  };

  useEffect(() => {
    onMinYearSelection(yearValues[0]);
    onMaxYearSelection(yearValues[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearValues]);

  const values = filterDatasetData(data.data, data.countries);

  const exhibit = data.exhibit;
  const minYear = exhibit?.start;
  const maxYear = exhibit?.end;
  const datasetLabels = data.datasets?.map((x) => x.label);
  const datasets = data.datasets.sort((a, b) => {
    const datasetA = exhibit?.datasets.find((x) => {
      return x.dataset === a.value;
    });
    const datasetB = exhibit?.datasets.find((x) => {
      return x.dataset === b.value;
    });
    return collator.compare(datasetA.axis, datasetB.axis);
  });
  let countries = data.countries?.map((x) => x.label);
  countries = [...new Set(countries)];

  let colorRangeSize;
  if (countries.length === 1) {
    colorRangeSize = datasetLabels.length;
  } else {
    colorRangeSize = countries.length;
  }

  const yAxisColor = themeColors.PRIMARY_COLOR;
  const y1AxisColor = themeColors.SECONDARY_COLOR;
  const borderStyles = [[0], [8, 8], [2, 2]];
  const colorRange = chroma
    .scale(["#915cac", "#be3074", "#5cac99"])
    .mode("lch")
    .colors(colorRangeSize);

    useEffect(() => {
    if (!data.datasets) return;
    const overlapOnly = exhibit && exhibit?.id !== "CUSTOM";
    const yearOptions = buildYearOptions(data.data, countries, overlapOnly, minYear, maxYear);
    setYearOptions(yearOptions);
  }, [data]); // eslint-disable-line

  useEffect(() => {
    setYearValues([yearOptions[0]?.value, yearOptions[yearOptions.length - 1]?.value]);
  }, [chartMode, yearOptions]); // eslint-disable-line

  useEffect(() => {
    if (yearValues) {
      generateCharts();
    }
  }, [chartMode, yearValues]); // eslint-disable-line

  const formatLabel = (dataValue, ctx) => {
    if (mode === tabViews.FREE_FORM) {
      const maxLineLength = 18;
      const value = exhibit ? ctx.dataset.datasetLabel : ctx.dataset.label;
      const words = value.split(" ");
      let result = "";
      let line = "";

      words.forEach((word) => {
        if ((line + word).length > maxLineLength) {
          result += line + "\n";
          line = word + " ";
        } else {
          line += word + " ";
        }
      });

      result += line;
      return result;
    } else {
      return "";
    }
  };

  const getBorderColor = (ctx) => ctx.dataset.borderColor;
  const getDisplay = (ctx) => ctx.dataIndex === ctx.dataset.data.length - 1;

  const generateCharts = () => {
    let newCharts = [];

    const years = yearOptions
      .filter((year) => {
        return isBetween(yearValues[0], yearValues[1], year.label);
      })
      .map((year) => {
        return year.label;
      });

    let pointRadius;
    if (yearOptions.length === 1) {
      pointRadius = 5;
    } else {
      pointRadius = 1.25;
    }

    let options = {
      elements: {
          line: {
              tension : 0.015 // smooth lines
          },
      },
      responsive: true,
      spanGaps: true,
      borderWidth: 2,
      borderColor: "#fff",
      pointRadius,
      pointHoverRadius: 5,
      hover: {
        intersect: false,
        mode: "index",
      },
      hitRadius: 10,
      layout: {
        padding: {
          right: mode === tabViews.FREE_FORM ? 100 : 0,
          top: -30,
          bottom: 0,
          left: 0,
        },
      },
      transitions: {
        show: {
          animations: {
            x: {
              from: 0,
            },
            y: {
              from: 0,
            },
          },
        },
        hide: {
          animations: {
            x: {
              to: 0,
            },
            y: {
              to: 0,
            },
          },
        },
      },
      plugins: {
        corsair: {
          color: "#888",
        },
        datalabels: {
          align: "right",
          color: getBorderColor,
          display: getDisplay,
          formatter: formatLabel,
          offset: 8,
          font: {
            weight: 600,
            size: 12,
          },
        },
        title: {
          display: false,
          font: {
            size: 20,
            weight: 700,
          },
          color: "#000000",
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.raw.label;
              if (mode === tabViews.CASE_STUDIES) {
                label += " -  Value: " + context.raw.rawValue.toLocaleString();
              } else if (chartMode.value === "rank") {
                label +=
                  " -  Rank: " +
                  context.raw.rank +
                  ", Value: " +
                  context.raw.rawValue.toLocaleString();
              } else {
                label +=
                  " -  Value: " +
                  context.raw.rawValue.toLocaleString() +
                  ", Rank: " +
                  context.raw.rank;
              }
              return label;
            },
          },
          position: "average",
          intersect: false,
          mode: "index",
          titleFont: {
            size: 16,
            weight: 700,
          },
          bodyFont: {
            size: 13,
            weight: 700,
          },
          padding: 10,
          boxPadding: 6,
          cornerRadius: 2,
          borderColor: "#ddd",
          borderWidth: 1,
          titleColor: "#000000",
          titleMarginBottom: 10,
          bodyColor: "#000000",
          bodySpacing: 8,
          backgroundColor: "rgba(255,255,255,1)",
        }
      },
      scales: {
        x: {
          offset: true,
          ticks: {
            color: "#141E3C",
            font: {
              weight: 700,
              size: 12,
            },
          },
          title: {
            display: true,
            text: "Date",
            font: {
              size: 14,
              weight: 700,
            },
            color: "#141E3C",
          },
          grid: {
            display: false,
          },
        },
        y: {
          offset: true,
          reverse: false,
          ticks: {
            color:
              exhibit && mode !== tabViews.FREE_FORM ? yAxisColor : y1AxisColor,
            font: {
              weight: 700,
              size: 12,
            },
            callback: function (value, index, values) {
              const formattedValue = value.toFixed(1).replace(/\.0$/, ""); // Limit to one decimal place
              return formattedValue;
            },
          },
          title: {
            display: true,
            text:
              mode === tabViews.FREE_FORM
                ? getAxisTitle(exhibit, datasets, "y")
                : exhibit.yAxisLabel,
            font: {
              size: 14,
              weight: 700,
            },
            color:
              exhibit && mode !== tabViews.FREE_FORM ? yAxisColor : y1AxisColor,
          },
        },
        y1: {
          position: "right",
          display: false,
          offset: true,
          ticks: {
            color: y1AxisColor,
            font: {
              weight: 700,
              size: 12,
            },
            callback: function (value, index, values) {
              const formattedValue = value.toFixed(1).replace(/\.0$/, ""); // Limit to one decimal place
              return formattedValue;
            },
            padding: mode === tabViews.FREE_FORM && !displayIndexValues && yearOptions.length > 1 ? 60 : 0,
            crossAlign: mode === tabViews.FREE_FORM && !displayIndexValues && yearOptions.length > 1
                ? "start"
                : undefined,
          },
          title: {
            display: true,
            text:
              mode === tabViews.FREE_FORM
                ? getAxisTitle(exhibit, datasets, "y1")
                : exhibit.y1AxisLabel,
            font: {
              size: 14,
              weight: 700,
            },
            padding: {
              top: mode === tabViews.FREE_FORM ? -25 : 0,
            },
            color: y1AxisColor,
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      },
    };

    let minValue;
    let maxValue;

    if (exhibit) {
      const isMultiAxis = datasets.find((dataset) => {
        return exhibit?.y1AxisLabel;
      });
      let yAxisCount = 0;
      let y1AxisCount = 0;
      let borderDash;
      let groupedData = [];

      datasets.forEach((dataset, idx) => {
        const exhibitDataset = exhibit?.datasets.find((x) => {
          return x.dataset === dataset.value;
        });
        const axis = exhibitDataset?.axis || "y";
        let labelSuffix = "";
        let color = "";
        let axisIndex = 0;

        if (isMultiAxis) {
          labelSuffix = axis === "y" ? "(left axis)" : "(right axis)";
          if (axis === "y") {
            borderDash = borderStyles[yAxisCount];
            //color = yAxisColors[Math.floor((yAxisCount) / 2)];
            color = yAxisColor;
            axisIndex = yAxisCount;
            yAxisCount++;
            //borderDash = yAxisCount % 2 === 0 ? [8,8] : [0];
          }

          if (axis === "y1") {
            borderDash = borderStyles[y1AxisCount];
            //color = y1AxisColors[Math.floor((y1AxisCount) / 2)];
            color = y1AxisColor;
            axisIndex = y1AxisCount;
            y1AxisCount++;
            //borderDash = y1AxisCount % 2 === 0 ? [12,12] : [0];
          }
        } else {
          borderDash = borderStyles[yAxisCount];
          color = yAxisColor;
          axisIndex = yAxisCount;
          yAxisCount++;
        }

        const data = {
          label: `${dataset.label} ${labelSuffix}`,
          valueOrder: dataset.valueOrder,
          description: dataset.description,
          backgroundColor: color,
          borderColor: color,
          legendColor: color,
          datasetLabel: dataset.label,
          yAxisID: axis,
          axisIndex,
          borderDash,
        };

        if (mode === tabViews.FREE_FORM) {
          const countryData = countries.map((country, yidx) => {
            return {
              ...data,
              label: `${dataset.label} ${labelSuffix}`,
              valueOrder: dataset.valueOrder,
              backgroundColor: colorRange[idx],
              borderColor: colorRange[idx],
              borderDash: [0],
              axisIndex: 0,
              legendColor: colorRange[idx],
              index: idx + 1,
              data: years.flatMap((year) => {
                const value = findValueByCountryAndYear(
                  values,
                  country,
                  year,
                  dataset.value
                );

                if (value) {
                  const chartValue =
                    chartMode.value === "rank"
                      ? reverse(parseFloat(value.rank_value))
                      : parseFloat(value.value);

                  //value = value > 0 ? value : "N/A";

                  if (chartValue > maxValue || !maxValue) {
                    maxValue = chartValue;
                  }

                  if (chartValue < minValue || !minValue) {
                    minValue = chartValue;
                  }

                  return [
                    {
                      x: year,
                      y: chartValue,
                      label: country,
                      rank: parseFloat(value.rank_value),
                      rawValue: parseFloat(value.value),
                      datasetLabel: dataset.label,
                    },
                  ];
                } else {
                  return [];
                }
              }),
            };
          });

          groupedData = [...groupedData, ...countryData];
        } else {
          data.data = years.flatMap((year) => {
            const index = values.findIndex((item) => {
              return (
                (item.year === year || item.date === year) &&
                item.dataset === dataset.label
              );
            });

            if (index >= 0) {
              let value = parseFloat(values[index].value);
              //value = value > 0 ? value : "N/A";

              if (value > maxValue || !maxValue) {
                maxValue = value;
              }

              if (value < minValue || !minValue) {
                minValue = value;
              }

              return [
                {
                  x: year,
                  y: value,
                  label: countries[0],
                  rank: parseFloat(values[index]["rank_value"]),
                  rawValue: parseFloat(values[index]["value"]),
                  datasetLabel: dataset.label,
                },
              ];
            } else {
              return [];
            }
          });
          groupedData.push(data);
        }

        return data;
      });

      if (isMultiAxis) {
        options = {
          ...options,
          scales: {
            ...options.scales,
            y1: {
              ...options.scales.y1,
              display: true,
            },
          },
        };
      }

      options.scales.x.ticks.callback = function (dataLabel, index, test) {
        return formatDate(years[index]);
      };

      options.scales.y.ticks.stepSize = getStepSize(minValue, maxValue);

      // options.scales.y.title.text = getAxisTitle(exhibit, datasets, "y");
      // options.scales.y1.title.text = getAxisTitle(exhibit, datasets, "y1");

      const chart = {
        datasets: {
          labels: years,
          datasets: groupedData,
        },
        title: data.title,
        descriptions: datasets.map((dataset) => dataset.description),
        options,
      };

      newCharts.push(chart);
    } else {
      datasets.forEach((dataset, idx) => {
        const groupedData = countries.map((country, yidx) => {
          return {
            label: country,
            valueOrder: dataset.valueOrder,
            data: years.flatMap((year) => {
              const value = findValueByCountryAndYear(
                values,
                country,
                year,
                dataset.value
              );

              if (value) {
                const chartValue =
                  chartMode.value === "rank"
                    ? reverse(parseFloat(value.rank_value))
                    : parseFloat(value.value);

                //value = value > 0 ? value : "N/A";

                if (chartValue > maxValue || !maxValue) {
                  maxValue = chartValue;
                }

                if (chartValue < minValue || !minValue) {
                  minValue = chartValue;
                }

                return [
                  {
                    x: year,
                    y: chartValue,
                    label: country,
                    rank: parseFloat(value.rank_value),
                    rawValue: parseFloat(value.value),
                  },
                ];
              } else {
                return [];
              }
            }),
            axisIndex: 0,
            backgroundColor: colorRange[countries.length === 1 ? idx : yidx],
            borderColor: colorRange[countries.length === 1 ? idx : yidx],
            legendColor: colorRange[countries.length === 1 ? idx : yidx],
            datasetLabel: dataset.label,
          };
        });

        if (chartMode.value === "rank") {
          options.scales.y.ticks.callback = function (dataLabel, index) {
            return reverse(dataLabel);
          };
          options.scales.y.title.text = "Rank";
          options.scales.y.min = 825;
          options.scales.y.max = 999;
        } else {
          options.scales.y.title.text =
            getAxisTitle(null, datasets, "y") || "Value";
        }

        options.scales.y.ticks.stepSize = getStepSize(minValue, maxValue);

        const chart = {
          datasets: {
            labels: years,
            datasets: groupedData,
          },
          title: dataset.label,
          descriptions: [dataset.description],
          options,
        };

        newCharts.push(chart);
      });
    }

    setCharts(newCharts);
  };

  const getChartOptions = (options) => {
    if (
      displayIndexValues &&
      chartMode.value !== "rank" &&
      datasets.length > 1
    ) {
      const newOptions = JSON.parse(JSON.stringify(options));
      return {
        ...newOptions,
        scales: {
          ...newOptions.scales,
          y: {
            ...newOptions.scales.y,
            title: { ...newOptions.scales.y, title: "" },
          },
          y1: null,
        },
        plugins: {
          ...newOptions.plugins,
          datalabels: {
            ...newOptions.plugins.dataLabels,
            align: "right",
            formatter: formatLabel,
            color: getBorderColor,
            display: getDisplay,
            offset: 8,
            font: {
              weight: 600,
              size: 12,
            },
          },
        },
      };
    } else {
      return options;
    }
  };

  const getChartData = (data) => {
    const minSharedYear =
      data.datasets.length > 1 && getMinSharedYear(data.datasets);

    let indexedLabels = [];
    if (displayIndexValues) {
      indexedLabels = data.labels.filter((label) => {
        return !minSharedYear || parseInt(label) >= parseInt(minSharedYear);
      });
    } else {
      indexedLabels = data.labels;
    }

    const indexedData = data.datasets.map((dataset) => {
      const newDataset = JSON.parse(JSON.stringify(dataset));
      let indexBaseValue;

      if (displayIndexValues) {
        if (minSharedYear) {
          newDataset.data = newDataset.data.filter((data) => {
            return (
              !minSharedYear || parseInt(data.x) >= parseInt(minSharedYear)
            );
          });
        }
      }

      if (
        displayIndexValues &&
        chartMode.value !== "rank" &&
        datasets.length > 1
      ) {
        newDataset.yAxisID = "y";
        newDataset.label = `${dataset.label
          .replace("(right axis)", "")
          .replace("(left axis)", "")} - ${dataset.datasetLabel}`;
      }

      newDataset.data = newDataset.data.map((data) => {
        const dataset = JSON.parse(JSON.stringify(data));
        if (dataset.y > 0 && !indexBaseValue) {
          indexBaseValue = dataset.y;
        }
        if (indexBaseValue) {
          dataset.indexValue = Math.round((dataset.y / indexBaseValue) * 100);
        } else {
          dataset.indexValue = 0;
        }
        if (displayIndexValues && chartMode.value !== "rank") {
          dataset.y = dataset.indexValue;
        } else if (chartMode.value === "rank") {
          dataset.y = reverse(dataset.rank);
        } else {
          dataset.y = dataset.rawValue;
        }
        return dataset;
      });

      return newDataset;
    });

    return { ...data, labels: indexedLabels, datasets: indexedData };
  };

  return (
    <>
      {charts.map((x, idx) => (
        <Fragment key={`${idx}-chart`}>
          <ChartWrapper>
            <Line
              ref={chartRef}
              options={getChartOptions(x.options)}
              data={getChartData(x.datasets)}
              plugins={[
                ChartDataLabels,
                Title,
                Tooltip,
                Corsair,
              ]}
            />
          </ChartWrapper>
          <ChartFooter>
            <YearNavigation
              onChange={handleYearSelection}
              value={yearValues}
              min={yearOptions[0]?.value}
              max={yearOptions[yearOptions.length - 1]?.value}
              range={true}
              yearOptions={yearOptions}
            />
            <ChartFooterData>
              {exhibit && (
                <>
                  <ChartFooterHeader>
                    <ChartLegend
                      chart={x}
                      data={getChartData(x.datasets)}
                      mode={mode}
                      countries={countries}
                    />
                    <Stats
                      data={data}
                      correlation={true}
                      showTitle={false}
                      selectedMinYear={yearValues[0]}
                      selectedMaxYear={yearValues[1]}
                    ></Stats>
                  </ChartFooterHeader>
                  <ChartDescription
                    chart={x}
                    datasets={datasets}
                    mode={mode}
                    isExhibit={exhibit}
                  />
                </>
              )}
              {data.source && (
                <ChartSource
                  dangerouslySetInnerHTML={{
                    __html: `<b>Sources</b>: ${data.source}`,
                  }}
                />
              )}
            </ChartFooterData>
          </ChartFooter>
        </Fragment>
      ))}
    </>
  );
}

export default LineChart;
