import { useEffect, useState, useCallback, useMemo } from "react";
import Spinner from "react-svg-spinner";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Dialog } from "@headlessui/react";
import { LinkIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  getCaseStudies,
  getCountries,
  getDatasets,
  getCategories,
  getResults,
} from "./actions";
import {
  buildCaseStudies,
  buildExhibits,
  buildCategories,
  buildCountries,
  buildDatasetGroups,
  filterCountries,
  filterDatasets,
  buildExhibitArticles,
  getDefaultDatasets,
  getDefaultCountry,
  getDefaultCountries,
  matchCountries,
  buildExhibitsFromDataset,
} from "./utils";
import { chartViews, selectStyles, tabViews } from "./constants";
import DefaultView from "./DefaultView";
import Results from "./Results";
import Stats from "./Stats";
import {
  Button,
  ChartContainer,
  Container,
  Field,
  Fields,
  GlobalStyle,
  PrintStyles,
  Loader,
  SelectInput,
  Sidebar,
  Tabs,
  Tab,
  DialogScroll,
  DialogPanel,
  DialogContainer,
  DialogBackdrop,
  DialogTitle,
  DialogCloseBtn,
  Icon,
  Exhibit,
  ExhibitActions,
  ExhibitForm,
  SavedExhibits,
  TextInput,
  InputField,
} from "./styled";
import "./App.css";
import RelatedArticles from "./RelatedArticles";

function App() {
  const [tabView, setTabView] = useState(tabViews.FREE_FORM);
  const [chartView, setChartView] = useState(chartViews.BAR_CHART);
  const [isFetching, setIsFetching] = useState(false);

  const [countryData, setCountryData] = useState([]);
  const [datasetData, setDatasetData] = useState([]);
  const [results, setResults] = useState(null);

  const [caseStudyOptions, setCaseStudyOptions] = useState([]);
  const [exhibitOptions, setExhibitOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [datasetOptions, setDatasetOptions] = useState([]);

  const [selectedCaseStudies, setSelectedCaseStudies] = useState(null);
  const [selectedExhibits, setSelectedExhibits] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [defaultCountries, setDefaultCountries] = useState(null);
  const [selectedPrimaryDatasets, setSelectedPrimaryDatasets] = useState(null);
  const [selectedSecondaryDatasets, setSelectedSecondaryDatasets] =
    useState(null);
  const [selectedTertiaryDatasets, setSelectedTertiaryDatasets] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedDatasetData, setSelectedDatasetData] = useState();

  const [savedExhibits, setSavedExhibits] = useLocalStorage("exhibits", []);
  const [exhibitName, setExhibitName] = useState("");

  const [isExhibitBuilderOpen, setIsExhibitBuilderOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [selectedMinYear, setSelectedMinYear] = useState();
  const [selectedMaxYear, setSelectedMaxYear] = useState();

  const [showStats, setShowStats] = useState(false);

  const filterOptions = (candidate, input) => {
    if (input.length > 2) {
      if (candidate.label.toLowerCase().includes(input.toLowerCase())) {
        return true;
      }
      if (candidate.data?.tags?.toLowerCase().includes(input.toLowerCase())) {
        return true;
      }
      return false;
    }
    return true;
  };

  const selectedDatasets = useMemo(() => {
    const datasets = [];
    if (selectedPrimaryDatasets) {
      datasets.push(selectedPrimaryDatasets[0]);
    }
    if (selectedSecondaryDatasets) {
      datasets.push(selectedSecondaryDatasets[0]);
    }
    if(selectedTertiaryDatasets) {
      datasets.push(selectedTertiaryDatasets[0]);
    }
    return datasets;
  }, [selectedPrimaryDatasets, selectedSecondaryDatasets, selectedTertiaryDatasets]);

  const saveExhibit = () => {
    const exhibits = [...savedExhibits];
    const datasets = selectedPrimaryDatasets.map((x) => x.value);

    if (selectedSecondaryDatasets) {
      datasets.concat(selectedSecondaryDatasets.map((x) => x.value));
    }

    const countries = selectedCountries.map((x) => x.value);

    exhibits.push({
      url: `?d=${datasets.join("|")}&c=${countries.join("|")}&id=${
        savedExhibits.length + 1
      }`,
      name: exhibitName,
      id: savedExhibits.length + 1,
      countries,
      datasets,
    });
    setSavedExhibits(exhibits);
    setExhibitName("");
  };

  const shareExhibit = (url) => {
    const location = document.referrer.split("?")[0];
    navigator.clipboard.writeText(`${location}data-analysis/${url}`);
  };

  const deleteExhibit = (id) => {
    const exhibits = [...savedExhibits];
    const index = exhibits.findIndex((exhibit) => {
      return exhibit.id === id;
    });

    exhibits.splice(index, 1);
    setSavedExhibits(exhibits);
  };

  const handleCaseStudySelection = (selectedOption) => {
    setSelectedCaseStudies(selectedOption);
    if (selectedOption) {
      const exhibits = buildExhibits(selectedOption.data.exhibits);
      setExhibitOptions(exhibits);
      handleExhibitSelection(exhibits[0]);
    } else {
      setSelectedExhibits(null);
    }

    setResults(null);
  };

  const handleExhibitSelection = async (selectedOption) => {
    setSelectedExhibits(selectedOption);

    if (selectedOption && selectedOption !== selectedExhibits) {
      const url = new URL(window.location);
      resetURL();
      url.searchParams.set("e", selectedOption.data.id);
      window.history.pushState({}, "", url);

      const exhibitDatasets = selectedOption.data.datasets.map(
        (x) => x.dataset
      );

      const filteredDatasets = filterDatasets(datasetData, exhibitDatasets);

      setSelectedPrimaryDatasets([filteredDatasets[0]]);
      setSelectedSecondaryDatasets(
        filteredDatasets[1] ? [filteredDatasets[1]] : null
      );
      setSelectedTertiaryDatasets(filteredDatasets[2] ? [filteredDatasets[2]] : null);
    } else {
      setSelectedPrimaryDatasets(null);
      setSelectedSecondaryDatasets(null);
      setSelectedTertiaryDatasets(null);
      setResults(null);
    }
  };

  const handleCategorySelection = (selectedOptions) => {
    //Clear other fields
    setCountryOptions([]);
    setSelectedPrimaryDatasets(null);
    setSelectedSecondaryDatasets(null);
    setSelectedTertiaryDatasets(null);
    //setSelectedCountries(null);
    setResults(null);

    setSelectedCategories(selectedOptions);
    if (selectedOptions.length > 0) {
      const filteredDatasets = buildDatasetGroups(
        datasetData,
        selectedOptions
      );
      setDatasetOptions(filteredDatasets);
    } else {
      setDatasetOptions(buildDatasetGroups(datasetData, categoryOptions));
    }
  };

  const handlePrimaryDatasetSelection = (selectedOptions) => {
    let datasetsArray = null;
    if (selectedOptions) {
      datasetsArray = Array.isArray(selectedOptions)
        ? selectedOptions
        : [selectedOptions];
      setSelectedPrimaryDatasets(datasetsArray);
    } else {
      setSelectedPrimaryDatasets(null);
    }
  };

  const handleSecondaryDatasetSelection = (selectedOptions) => {
    let datasetsArray = null;
    if (selectedOptions) {
      datasetsArray = Array.isArray(selectedOptions)
        ? selectedOptions
        : [selectedOptions];
      setSelectedSecondaryDatasets(datasetsArray);
    } else {
      setSelectedSecondaryDatasets(null);
    }
  };


  const handleCountrySelection = (selectedOptions) => {
    setResults(null);
    let countries = selectedOptions
      ? Array.isArray(selectedOptions)
        ? selectedOptions
        : [selectedOptions]
      : [];

    if (selectedDatasets.length > 1 && countries[0]) {
      countries = [countries[0]];
    }
    setSelectedCountries(countries);
    const url = new URL(window.location);
    if(countries.length > 0){
      url.searchParams.set(
        "c",
        countries?.map((option) => option.value).join("|")
      );
    } else {
      url.searchParams.delete("c");
    }
    window.history.pushState({}, "", url);
  };

  // const loadCountries = (datasetData) => {
  //   const filteredCountries = filterCountries(datasetData, countryData);
  //   setCountryOptions(filteredCountries);
  // };

  const generateResults = useCallback(() => {
    if (selectedDatasets.length > 0) {
      const countries = selectedCountries?.length > 0 ? selectedCountries : [];

      let exhibit;
      if (selectedDatasets.length > 1) {
        exhibit = buildExhibitsFromDataset(
          selectedDatasets,
          selectedDatasetData,
          countries
        );
      }

      const url = new URL(window.location);
      const exhibitId = url.searchParams.get("id");

      let exhibitTitle;

      if (exhibitId) {
        exhibitTitle = savedExhibits.find((exhibit) => {
          return exhibit.id === parseInt(exhibitId);
        })?.name;
      }

      setResults({
        data: selectedDatasetData,
        countries,
        datasets: selectedDatasets,
        exhibit,
        exhibitTitle,
        source: selectedDatasets.map((dataset) => dataset.source).join(", "),
        valueLabel: selectedDatasets[0].valueLabel,
      });

      exhibit?.defaultChartView && setChartView(exhibit?.defaultChartView);
    }
  }, [selectedDatasetData, selectedCountries, savedExhibits, selectedDatasets]);

  const generateExhibitResults = () => {
    if (selectedDatasets.length > 0) {
      const countries = filterCountries(selectedDatasetData, countryData);

      setResults({
        data: selectedDatasetData,
        countries,
        datasets: selectedDatasets,
        exhibit: selectedExhibits.data,
        articles: buildExhibitArticles(selectedExhibits),
        title: selectedExhibits.label,
      });
      setChartView(
        tabView === tabViews.CASE_STUDIES &&
          selectedExhibits.data.defaultChartView
          ? selectedExhibits.data.defaultChartView
          : chartViews.BAR_CHART
      );
    }
  };

  const autoLoadExhibit = (exhibitId) => {
    const caseStudy = caseStudyOptions.find((x) => {
      return x.data.exhibits.find((y) => {
        return y.id === exhibitId;
      });
    });

    if (caseStudy) {
      setSelectedCaseStudies(caseStudy);
      const exhibitOptions = buildExhibits(caseStudy.data.exhibits);
      setExhibitOptions(exhibitOptions);


      const exhibit = exhibitOptions.find((y) => {
        return y.value === exhibitId;
      });
  
      if (exhibit) {
        handleExhibitSelection(exhibit);
      }
    }
  };

  const selectedExhibitArticles = (exhibit) => {
    return buildExhibitArticles(exhibit);
  };

  const handleReset = () => {
    setSelectedCaseStudies(null);
    setSelectedExhibits(null);
    setSelectedCategories(null);
    setSelectedPrimaryDatasets(null);
    setSelectedSecondaryDatasets(null);
    setSelectedTertiaryDatasets(null);
    setSelectedCountries(null);
    setResults(null);
    resetURL();
  };

  const resetURL = () => {
    const url = new URL(window.location);
    url.searchParams.delete("d");
    url.searchParams.delete("c");
    url.searchParams.delete("y");
    url.searchParams.delete("m");
    url.searchParams.delete("id");
    url.searchParams.delete("e");
    window.history.pushState({}, "", url);
  };

  const handleTabClick = (nextTabView) => {
    if (tabView !== nextTabView) {
      handleReset();
      setTabView(nextTabView);
    }
  };

  // Load initial data
  useEffect(() => {
    Promise.all([
      getCategories(),
      getCountries(),
      getCaseStudies(),
      getDatasets(),
    ]).then(([categories, countries, caseStudies, datasets]) => {
      const categoryOptions = buildCategories(categories.data);
      setCategoryOptions(categoryOptions);
      setCountryData(countries.data);
      setCountryOptions(buildCountries(countries.data));
      setCaseStudyOptions(buildCaseStudies(caseStudies));
      setDatasetData(datasets.data);
      setDatasetOptions(buildDatasetGroups(datasets.data, categoryOptions));
    });
  }, []);

  // All: Auto load case exhibit or datasets from URL after all data is loaded
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);

    const exhibit = searchParams.get("e");
    const datasetIds = searchParams.get("d")?.split("|");

    if(caseStudyOptions){
      if (!exhibit) {
        const defaultDatasets = getDefaultDatasets(
          datasetData,
          datasetIds || undefined
        );

        if (defaultDatasets.length > 0) {
          setSelectedPrimaryDatasets([defaultDatasets[0]]);
          if (defaultDatasets.length > 1) {
            setSelectedSecondaryDatasets([defaultDatasets[1]]);
          }
        }
      } else {
        setTabView(tabViews.CASE_STUDIES);
        autoLoadExhibit(exhibit);
      }
    }
  }, [caseStudyOptions]); // eslint-disable-line

  // Free Form: Generate results on country selection
  useEffect(() => {
    if (tabView === tabViews.FREE_FORM) {
      generateResults();
    }
  }, [selectedCountries, selectedDatasetData]); // eslint-disable-line

  // Case Studies: Generate results after dataset data is loaded
  useEffect(() => {
    if (tabView === tabViews.CASE_STUDIES) {
      generateExhibitResults();
    }
  }, [selectedCountries, selectedDatasetData]); // eslint-disable-line

  // All: Load dataset(s) and default country
  useEffect(() => {
    setCountryOptions([]);
    setResults(null);

    const url = new URL(window.location);
    const countryIds = url.searchParams.get("c") && url.searchParams.get("c")?.split("|");
    const datasets = selectedDatasets.map((x) => x.value);

    if (selectedDatasets?.length > 0) {

      const loadDatasets = async (datasets) => {
        let countries;
        
        setIsFetching(true);
        
        const results = await getResults(datasets);

        setIsFetching(false);
        setSelectedDatasetData(results.data);

        const filteredCountries = filterCountries(results.data, countryData);
  
        setCountryOptions(filteredCountries);
    
        if(!url.searchParams.get("e")){
          url.searchParams.set("d", datasets.join("|"));
          window.history.pushState({}, "", url);
        }

        setChartView(chartViews.LINE_CHART);

        if (
          filteredCountries.length > 0
        ) {
          if (countryIds) {
            countries = getDefaultCountries(filteredCountries, countryIds);
          } else if (!selectedCountries) {
            countries = getDefaultCountries(filteredCountries);
          } else {
            // Check if selected countries are in dataset, if not select a default
            const matchedCountries = matchCountries(
              selectedCountries,
              filteredCountries
            );
          
            if (matchedCountries.length > 0) {
              countries = matchedCountries;
            } else {
              countries = getDefaultCountry(filteredCountries);
            }
          }
    
          if (selectedSecondaryDatasets) {
            countries = [countries[0]];
          }
  
          setDefaultCountries(countries);

          if(!url.searchParams.get("e")){
            url.searchParams.set(
              "c",
              countries.map((option) => option.value).join("|")
            );
          }
          window.history.pushState({}, "", url);
        }
      }

      loadDatasets(datasets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatasets]);

  useEffect(() => {
    if(defaultCountries){
      setSelectedCountries(defaultCountries);
    }
  }, [countryOptions, defaultCountries]);

  const handleChartView = (view) => {
    setChartView(view);
  };

  const getDatasetNames = (savedExhibit) => {
    if (savedExhibit) {
      const datasets = datasetData.filter((dataset) => {
        return savedExhibit.datasets.includes(dataset.dataset_id);
      });
      return datasets?.map((dataset) => dataset.title).join(", ");
    } else {
      return selectedDatasets
        ?.map((dataset) => {
          return dataset.label;
        })
        .join(", ");
    }
  };

  const getCountryNames = (savedExhibit) => {
    if (savedExhibit) {
      const countries = countryData.filter((country) => {
        return savedExhibit.countries.includes(country.code);
      });
      const uniqueCountries = [
        ...new Map(countries.map((item) => [item["code"], item])).values(),
      ];

      return uniqueCountries?.map((country) => country.name).join(", ");
    } else {
      return selectedCountries
        ?.map((country) => {
          return country.label;
        })
        .join(", ");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
      <Container>
        <Dialog
          open={isExhibitBuilderOpen}
          onClose={() => setIsExhibitBuilderOpen(false)}
        >
          <DialogBackdrop aria-hidden="true" />
          <DialogScroll>
            <DialogContainer>
              <DialogPanel>
                <DialogCloseBtn onClick={() => setIsExhibitBuilderOpen(false)} active={true}>
                  <Icon as={XMarkIcon} color="dark" />
                </DialogCloseBtn>
                <DialogTitle>Exhibits</DialogTitle>
                {results && (
                  <ExhibitForm>
                    <h3>{`Create a new exhibit`}</h3>
                    <p>
                      <strong>Datasets:</strong>
                      <br />
                      {getDatasetNames()}
                    </p>
                    <p>
                      <strong>Countries:</strong>
                      <br />
                      {getCountryNames()}
                    </p>
                    {selectedDatasets?.length > 0 && (
                      <InputField>
                        <label>Name:</label>
                        <TextInput
                          value={exhibitName}
                          onChange={(e) => setExhibitName(e.target.value)}
                          type="text"
                        />
                        <Button size="xs" active={true} onClick={saveExhibit}>
                          Save
                        </Button>
                      </InputField>
                    )}
                  </ExhibitForm>
                )}
                {savedExhibits?.length > 0 && (
                  <SavedExhibits>
                    <h3>Saved Exhibits</h3>
                    {savedExhibits.map((savedExhibit, idx) => {
                      return (
                        <Exhibit key={`exhibit-${idx}`}>
                          <div>
                            <a href={savedExhibit.url}>{savedExhibit.name}</a>
                            <p>
                              <strong>Datasets:</strong>
                              <br />
                              {getDatasetNames(savedExhibit)}
                            </p>
                            <p>
                              <strong>Countries:</strong>
                              <br />
                              {getCountryNames(savedExhibit)}
                            </p>
                          </div>
                          <ExhibitActions>
                            <Button
                              size="icon"
                              active={true}
                              onClick={() => shareExhibit(savedExhibit.url)}
                            >
                              <Icon as={LinkIcon} />
                            </Button>
                            <Button
                              size="icon"
                              active={true}
                              onClick={() => deleteExhibit(savedExhibit.id)}
                            >
                              <Icon as={TrashIcon} />
                            </Button>
                          </ExhibitActions>
                        </Exhibit>
                      );
                    })}
                    <p>
                      <strong>Note:</strong> We recommend you bookmark your
                      exhibit URLs
                    </p>
                  </SavedExhibits>
                )}
              </DialogPanel>
            </DialogContainer>
          </DialogScroll>
        </Dialog>
        <GlobalStyle />
        <PrintStyles />
        <ChartContainer>
          {!isFetching &&
          results?.datasets?.length > 0 &&
          results?.countries?.length > 0 ? (
            <Results
              results={results}
              countryOptions={countryOptions}
              onReset={handleReset}
              chartView={chartView}
              onChartView={handleChartView}
              mode={tabView}
              toggleSidebar={toggleSidebar}
              isSidebarOpen={isSidebarOpen}
              onMinYearSelection={setSelectedMinYear}
              onMaxYearSelection={setSelectedMaxYear}
            />
          ) : (
            !isFetching && <DefaultView />
          )}
          {isFetching && (
            <Loader>
              Loading
              <Spinner size="100" color="#d55e27" />
            </Loader>
          )}
        </ChartContainer>
        <Sidebar isOpen={isSidebarOpen}>
          <div>
            <Tabs>
              <Tab
                onClick={() => {
                  handleTabClick(tabViews.FREE_FORM);
                }}
                role="tab"
                active={tabView === tabViews.FREE_FORM}
              >
                Data Explorer
              </Tab>
              <Tab
                onClick={() => {
                  handleTabClick(tabViews.CASE_STUDIES);
                }}
                role="tab"
                active={tabView === tabViews.CASE_STUDIES}
              >
                Case Exhibits
              </Tab>
            </Tabs>
            {tabView === tabViews.CASE_STUDIES ? (
              <>
                <Fields>
                  <Field>
                    <label htmlFor="case-studies">Case Exhibits</label>
                    <SelectInput
                      id="case-studies"
                      isClearable
                      value={selectedCaseStudies}
                      options={caseStudyOptions}
                      onChange={handleCaseStudySelection}
                      filterOption={filterOptions}
                    />{" "}
                  </Field>
                  <Field>
                    <label htmlFor="exhibits">Exhibits</label>
                    <SelectInput
                      id="exhibits"
                      isClearable
                      isSearchable
                      value={selectedExhibits}
                      options={exhibitOptions}
                      onChange={handleExhibitSelection}
                      isDisabled={!selectedCaseStudies}
                      filterOption={filterOptions}
                    />{" "}
                  </Field>
                </Fields>
              </>
            ) : (
              <>
                <Fields>
                  <Field>
                    <label htmlFor="topics">Topic</label>
                    <small>Filter dataset A & B options by topic</small>
                    <SelectInput
                      id="topics"
                      isClearable
                      isSearchable
                      isMulti
                      styles={selectStyles}
                      value={selectedCategories}
                      options={categoryOptions}
                      onChange={handleCategorySelection}
                    />{" "}
                  </Field>
                  <Field>
                    <label htmlFor="datasets">Dataset A</label>
                    <small>Search by title or keyword</small>
                    <SelectInput
                      id="datasets"
                      isClearable
                      isSearchable
                      styles={selectStyles}
                      value={selectedPrimaryDatasets}
                      options={datasetOptions}
                      onChange={handlePrimaryDatasetSelection}
                      isOptionDisabled={() => selectedDatasets?.length >= 2}
                      filterOption={filterOptions}
                    />
                  </Field>
                  <Field>
                    <label htmlFor="datasets-secondary">
                      Dataset B (optional)
                    </label>
                    <small>Search by title or keyword</small>
                    <SelectInput
                      id="datasets-secondary"
                      isClearable
                      isSearchable
                      styles={selectStyles}
                      value={selectedSecondaryDatasets}
                      options={datasetOptions}
                      onChange={handleSecondaryDatasetSelection}
                      isOptionDisabled={() =>
                        selectedSecondaryDatasets?.length >= 2
                      }
                      filterOption={filterOptions}
                    />
                  </Field>
                  <Field>
                    <label htmlFor="countries">Countries</label>
                    <SelectInput
                      id="countries"
                      isClearable
                      isSearchable
                      isMulti={selectedDatasets.length === 1}
                      styles={selectStyles}
                      value={selectedCountries}
                      options={countryOptions}
                      onChange={handleCountrySelection}
                      isDisabled={
                        !selectedDatasets || selectedDatasets?.length === 0
                      }
                    />
                  </Field>
                </Fields>
              </>
            )}
            {results?.length > 0 && (
              <Button onClick={handleReset}>Clear All</Button>
            )}
          </div>
          {selectedExhibits &&
            selectedExhibitArticles(selectedExhibits).length > 0 && (
              <div className="widget">
                <RelatedArticles
                  articles={selectedExhibitArticles(selectedExhibits)}
                />
              </div>
            )}
          {selectedDatasets && selectedDatasets[0]?.articles?.length > 0 && (
            <div className="widget">
              <RelatedArticles articles={selectedDatasets[0].articles} />
            </div>
          )}
          <div className="widgets">
            {tabView !== tabViews.CASE_STUDIES && (
              <>
                {(savedExhibits.length > 0 ||
                  selectedPrimaryDatasets ||
                  selectedSecondaryDatasets) && (
                  <div className="widget">
                    <Button
                      onClick={() => setIsExhibitBuilderOpen(true)}
                      size="sm"
                      active={true}
                    >
                      Manage/Save Exhibits
                    </Button>
                  </div>
                )}
                {results?.datasets.length > 0 &&
                  chartView === chartViews.LINE_CHART && (
                    <div className="widget">
                      {showStats && (
                        <Stats
                          data={results}
                          selectedMinYear={selectedMinYear}
                          selectedMaxYear={selectedMaxYear}
                        ></Stats>
                      )}
                      <Button
                        onClick={() => setShowStats(!showStats)}
                        size="sm"
                        active={true}
                      >
                        {!showStats ? "Show" : "Hide"} Statistics
                      </Button>
                    </div>
                  )}
              </>
            )}
          </div>
        </Sidebar>
      </Container>
  );
}

export default App;
