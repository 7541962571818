import styled, { createGlobalStyle } from "styled-components";
import Select from "react-select";
import { Dialog as HUDialog } from "@headlessui/react";
import {
  TooltipContent as TooltipContentComponent,
  TooltipTrigger as TooltipTriggerComponent,
} from "./Tooltip";
import YearNavigation from "./YearNavigation";

export const TextInput = styled.input`
  border: 1px solid var(--gray);
  padding: 0.5rem;
  border-radius: 0.25rem;
`;

export const InputField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  label {
    margin: 0 0.5rem 0 0;
  }

  input {
    flex-grow: 1;
    margin-right: 0.5rem;
  }
`;

export const SavedExhibits = styled.div`
  border-top: 1px solid var(--light-gray);
  padding-top: 1rem;
`;

export const ExhibitForm = styled.div`
  padding-bottom: 1rem;

  p {
    margin: 0 0 0.5rem 0;
  }

  ${InputField} {
    margin-top: 1rem;
  }
`;

export const Exhibit = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 1rem;
`;

export const ExhibitActions = styled.div`
  display: flex;
  margin-left: auto;

  button {
    margin-left: 0.5rem;
  }
`;

export const Icon = styled.div`
  width: 1.25rem;
  height: 1.25rem;
`;

export const DialogScroll = styled.div`
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 4rem;
`;

export const DialogCloseBtn = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: var(--lighter-gray);
  
  svg {
    fill: var(--blue);
  }
`;

export const DialogBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
`;
export const DialogContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DialogPanel = styled(HUDialog.Panel)`
  width: 40rem;
  position: relative;
  background: white;
  padding: 1.25rem;
`;

export const DialogTitle = styled(HUDialog.Title)`
  margin: 0 0 1rem 0;
`;

export const DialogDescription = styled(HUDialog.Description)`
  margin: 0 0 1.5rem 0;
  font-size: 1.25rem;
`;

export const GlobalStyle = createGlobalStyle`
 * {
  box-sizing: border-box;
 }
  :root {
    --gray: #686868;
    --light-gray: #ccc;
    --lighter-gray: #efefef;
    --blue: #141E3C;
    --blue-light: #39497b; 
    --orange: #d55e27;
  }

  body {
    font-family: "franklin-gothic-urw",sans-serif;
    color: #252525;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #232D4B;
    margin: 0 0 0.25rem 0;
  }

  h4 {
  line-height: 1.4;
  font-weight: 600;
}


h5 {
  line-height: 1.4;
  font-size: 0.9375rem;
  font-weight: 600;
}

  ul {
    margin: 0;
    padding-left: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
    padding-left: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: #252525;
    text-decoration: underline;
  }

  label {
    display: block;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  button {
    font-family: "franklin-gothic-urw",sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    background: #141E3C;
    color: #fff;
    padding: 1rem;
    border: none;
    cursor: pointer;
  }

  p {
    font-size: 0.875rem;
  }

.widgets {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.widget {
  font-size: 0.9375rem;
}

.stat-group {
  margin-bottom: 1rem;

  h3 {
    font-size: 1rem;
  }
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 1rem;
  th {
    text-align: left;
    padding: 0.5rem;
    border: 1px solid #141E3C;
  }
  td {
    padding: 0.5rem;
    border: 1px solid #141E3C;
  }
}

.rc-slider {
  max-width: 98%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 0.5rem;
  background-color: #ccc;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  height: 0.5rem;
  background-color: var(--blue);
  border-radius: 6px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
}
.rc-slider-handle {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -5px;
  background-color: #fff;
  border: 2px solid var(--blue);
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 1;
  touch-action: pan-x;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: var(--blue);
  box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: var(--blue);
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: var(--blue);
}
.rc-slider-handle:active {
  border-color: var(--blue);
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 13px;
  color: #1d252d;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #1d252d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  top: 8px;
  font-weight: 600;
  display: none;
}
.rc-slider-mark-text-active {
  color: #1d252d;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 4px;
  height: 4px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
  display: none;
}
.rc-slider-dot-active {
  border-color: tint(#2db7f5, 50%);
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  display: none;
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
  background-color: var(--blue);;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip,
.rc-slider-tooltip-always-on-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden,
.rc-slider-tooltip-always-on-tooltip-hidden {
  visibility: hidden;
}
.rc-slider-tooltip-placement-top,
.rc-slider-tooltip-always-on-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner,
.rc-slider-tooltip-always-on-tooltip-inner {
  min-width: 24px;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background-color: var(--blue);
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
  white-space: nowrap;
}
.rc-slider-tooltip-arrow,
.rc-slider-tooltip-always-on-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
/* .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #000;
} */

`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: ${(props) => (props.active ? "none" : "0.1rem solid var(--blue)")};
  font-size: 0.875rem;
  color: white;
  background: ${(props) => (props.active ? "var(--blue)" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "var(--blue)")};
  min-width: 2rem;
  height: 2rem;
  text-decoration: none;

  svg {
    align-self: center;
    width: 1rem;
    height: 1rem;
    fill: ${(props) => (props.active ? "#fff" : "var(--blue)")};
  }

  span {
    display: block;
    margin-right: 0.5rem;
    font-weight: 700;
  }

  svg + span,
  img + span {
    margin-left: 0.5rem;
    margin-right: 0;
  }

  &[disabled] {
    opacity: 0.25;
    cursor: default;
  }

  ${(props) => {
    if (props.size === "sm") {
      return `
      padding: 0.75rem;
      line-height: 1;
      `;
    }
    if (props.size === "xs") {
      return `
      padding: 0 0.75rem;
      line-height: 2.25;
      `;
    }

    if (props.size === "icon") {
      return `
      padding: 0.5rem;
      `;
    }
  }}
`;

export const Loader = styled.div`
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;

  svg {
    margin-top: 1rem;
  }
`;

export const DownloadButton = styled.a``;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex: none;
  width: 20rem;
  padding: 1rem;
  position: relative;
  background: #eee;
  transition: 0.25s cubic-bezier(0.36, -0.01, 0, 0.77);
  margin-right: ${(props) => (props.isOpen ? "0" : "-340px")};
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
`;

export const SidebarToggle = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  transform: translateX(-100%);
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px var(--light-gray);
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  canvas {
    max-height: 70vh;
  }
`;

export const ResultsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: ${(props) =>
    props.isSidebarOpen ? "1rem" : "1rem 2.25rem 1rem 1rem"};
`;

export const Field = styled.div`
  display: flex;
  align-items: ${(props) =>
    props.layout === "horizontal" ? "center" : ""};
  flex-direction: ${(props) =>
    props.layout === "horizontal" ? "row" : "column"};
  label {
    font-size: 0.875rem;
    margin: 0 0.5rem 0 0;
  }
`;


export const SelectInput = styled(Select)`
  max-width: 500px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  row-gap: 1rem;
  padding: 1rem;
  width: 100%;
  background: var(--blue);

  label {
    color: #fff;
    margin-bottom: 0.25rem;
  }

  small {
    display: block;
    color: #fff;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;


export const Label = styled.label`
  font-weight: 700;
  margin: 0 1rem 1rem 1rem;

  ${SelectInput} {
    margin-bottom: 0;
  }
`;

export const Chart = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  flex: 1;
  margin: 0 auto;
  background: white;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ChartDescription = styled.h2`
  font-size: 1rem;
  font-weight: 400;
`;

export const ChartSubtitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const ChartTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

export const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ChartYAxis = styled.div`
  position: absolute;
  top: 50%;
  left: -1.5rem;
  transform: rotate(-90deg) translate(0%, 50%);
  transform-origin: left top;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`;

export const ChartY1Axis = styled.div`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: rotate(-270deg) translate(0%, 50%);
  transform-origin: right bottom;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`;

export const ExhibitTitle = styled.div`
  font-size: 1rem;
  text-align: center;
  max-width: 75%;
  font-weight: 700;
  color: #232d4b;
  margin: 0 auto 0.25rem;
`;

export const ChartSource = styled.p`
  font-size: 0.875rem;
  margin: 0 0 0.5rem;
`;

export const ChartValueLabel = styled.p`
  font-size: 1rem;
  color: var(--blue);
  text-align: center;
  max-width: 75%;
  font-weight: 600;
  margin: 0 auto 1rem;
`;

export const ChartToolbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartZoom = styled.div`
  position: absolute;
  top: -40px;
  right: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;

  ${Button} {
    padding: 0.25rem 0.5rem;
    margin: 0 0.125rem;
    min-width: 2rem;
    min-height: 2rem;
    font-size: 0.875rem;
    line-height: 1.2;
    justify-content: center;
    background: var(--blue);

    &:first-of-type {
      margin-left: 1rem;
    }

    svg {
      fill: white;
      width: 0.5rem;
    }
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: ${(props) => (props.align === "right" ? "flex-end" : "flex-start")};

  ${DownloadButton} {
    align-self: center;
  }
`;

export const ButtonBarLabel = styled.div`
  font-weight: 700;
  margin-right: 1rem;
`;

export const RelatedContent = styled.div`
  font-size: 0.875rem;
`;

export const Tabs = styled.div``;

export const Tab = styled.button`
  font-size: 1rem;
  background: ${(props) => (props.active ? "var(--blue)" : "var(--gray)")};
  color: ${(props) => (props.active ? "#fff" : "#fff")};
  padding: 0.5rem 1rem;
`;

export const Description = styled.li`
  font-size: 0.9375rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  &:last-child {
    margin: 0;
  }
`;

export const DescriptionList = styled.ol`
  margin: 0;
  padding: 0 0 0 1rem;
  list-style-position: outside;
`;

export const Descriptions = styled.ul`
  margin: 0 0 0.75rem 0;
  padding: 0;
  list-style-position: outside;
  list-style-type: none;
`;

export const Swatch = styled.div`
  width: 1rem;
  height: 1rem;
  background: ${(props) => (props.axisIndex >= 1 ? "none" : props.background)};
  border-width: 2px;
  border-color: ${(props) => props.background};
  ${(props) => {
    if (props.axisIndex === 1) {
      return `border-style: dashed;`;
    }
    if (props.axisIndex === 2) {
      return `border-style: dotted;`;
    }
  }}
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;

  ${Swatch} {
    margin-right: 0.5rem;
    flex: none;
  }

  span {
    flex: none;
  }
`;

export const Legend = styled.div`
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin-top: 0.25rem;
  display: flex;
  flex-wrap: wrap;

  ${LegendItem} {
    max-width: 80%;
  }
`;

export const TooltipContent = styled(TooltipContentComponent)`
  background-color: #444;
  color: white;
  font-size: 90%;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  width: max-content;
  max-width: 20rem;
`;

export const TooltipTrigger = styled(TooltipTriggerComponent)`
  background: none;
  padding: 0;

  img {
    width: 0.75rem;
  }
  span {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const Stats = styled.div`
  background: ${(props) => (props.correlation ? "none" : "none")};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StatGroup = styled.div`
  h4 {
    font-size: 1rem;
    line-height: 1.2;
    margin: 0 0 0.5rem 0;
    color: var(--orange);
  }

  h5 {
    font-weight: 700;
    color: var(--orange);
  }

  p {
    margin: 0 0 0.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button,
    span {
      display: flex;
    }
    
  }

  .country-stats {
    margin-bottom: 1rem;
  }

  .label {
    margin-bottom: 0.75rem;
  }

  &.correlation {
    align-self: flex-end;
    flex: none;
  }

  &.correlation button {
    margin-left: 0.5rem;
  }

  .value {
    font-size: 0.875rem;
  }

  strong {
    margin-right: 0.25rem;
  }

  span {
    display: flex;
    align-items: center;
  }
`;

export const ChartFooter = styled.div`
  display: flex;
  flex-direction: column;

  ${Stats} {
    font-size: 0.875rem;
    width: auto;
    padding: 0;
  }

  ${StatGroup} {
    p {
      background: none;
    }
  }
`;

export const ChartFooterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const ChartFooterData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
`;

export const Checkbox = styled.div`
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
    margin: 0 0.25rem 0 0;
    border: 0.125rem solid var(--blue);
    outline: none;
    cursor: pointer;
  }
  label {
    display: flex;
    align-items: center;
  }
  input:checked {
    background-color: var(--blue);
    position: relative;
  }
  input:checked::before {
    content: "";
    display: block;
    width: 0.15rem;
    height: 0.5rem;
    font-size: 1.25rem;
    color: #fff;
    display: inline-block;
    transform: ;
    border-bottom: 0.15rem solid #fff;
    border-right: 0.15rem solid #fff;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
`;

export const IndexValuesBtn = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  label {
    margin: 0;
  }
`;

export const ChartDataToggle = styled(Checkbox)`
  margin-right: 0.25rem;
  font-size: 0.875rem;
`;

export const YearNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;

  .rc-slider {
    margin: -0.25rem 1rem 0 1rem;
  }
`;

export const RangeButton = styled.button`
  flex: none;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
`;

export const YearNavigationStyled = styled(YearNavigation)`
`;

export const PrintStyles = createGlobalStyle`
  /* @media print {
      ${Sidebar}, ${Toolbar}, ${YearNavigationStyled}, button {
        display: none;
      }

      canvas {
        width: 100%;
    height: auto;
    page-break-inside: avoid;
      }
    } */
`;