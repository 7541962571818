import React, { forwardRef, useCallback } from "react";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import dayjs from "dayjs";
import { Range as RcRange, createSliderWithTooltip } from "rc-slider";
import { formatDate } from "./utils";
import { dateFormat } from "./constants";
import { YearNavigationWrapper, RangeButton } from "./styled";
const Range = createSliderWithTooltip(RcRange);
const BasicSlider = createSliderWithTooltip(Slider);

const YearNavigation = forwardRef(
  (
    {
      className,
      value,
      min,
      max,
      onChange,
      onMinValue,
      onMaxValue,
      data,
      range,
      yearOptions,
      ...props
    },
    ref
  ) => {

    const useUnix = String(min)?.includes("-");
    let minValue;
    let maxValue;
    let values;
    if(useUnix) {
       minValue = dayjs(min).unix();
       maxValue = dayjs(max).unix();
       values = [dayjs(value[0]).unix(), dayjs(value[1]).unix()];
    } else {
       minValue = min ? parseInt(min) : 0;
       maxValue = max ? parseInt(max) : 0;
       values = value;
    }
   
    const formatTip = useCallback(
      (value) => {
        if(useUnix){
          return `${dayjs.unix(value).format(dateFormat)}`;
        } else {
           return value;
        }
        
      },
      [useUnix]
    );

    const handleMinValue = () => {
      const maxValue = range ? values[1] : minValue;
      if(useUnix){
        onChange([dayjs.unix(minValue).format(dateFormat), dayjs.unix(maxValue).format(dateFormat)]);
      } else {
        onChange([String(minValue), String(maxValue)]);
      }
    }
    
    const handleMaxValue = () => {
      const minValue = range ? values[0] : maxValue;
      if(useUnix){
        onChange([dayjs.unix(minValue).format(dateFormat), dayjs.unix(maxValue).format(dateFormat)]);
      } else {
        onChange([String(minValue), String(maxValue)]);
      }
    }

    const handleRangeChange = (values) => {
      if(useUnix){
        onChange([dayjs.unix(values[0]).format(dateFormat), dayjs.unix(values[1]).format(dateFormat)]);
      } else {
        onChange([String(values[0]), String(values[1])]);
      }
    }

    const handleChange = (value) => {
      if(useUnix){
        onChange([dayjs.unix(value).format(dateFormat), dayjs.unix(value).format(dateFormat)]);
      } else {
        onChange([String(value), String(value)]);
      }
    }

    //console.log("min-max", minValue, maxValue, value);
  
    let marks = {};
    yearOptions.forEach((year, idx) => {
      let yearValue;
      let key;
      if(useUnix) {
        yearValue = dayjs(year.value).unix();
        key = dayjs(year.value).unix();
      } else {
        yearValue =  parseInt(year.value);
        key = parseInt(yearValue);
      }
      marks[key] = yearValue;
    });

    const getButtonLabel = (value) => {
      if(useUnix){
        return formatDate(dayjs.unix(value).format(dateFormat));
      } else {
        return formatDate(value);
      }
    }

    return (
      <YearNavigationWrapper>
        <RangeButton onClick={handleMinValue}>{getButtonLabel(minValue)}</RangeButton>
        {range ? (
            <Range
              ref={ref}
              className={className}
              allowCross={false}
              range={true}
              min={minValue}
              max={maxValue}
              marks={marks}
              value={values}
              step={null}
              onChange={handleRangeChange}
              draggableTrack
              tipFormatter={formatTip}
              {...props}
            />
        ) : (
          <BasicSlider
            ref={ref}
            className={className}
            allowCross={false}
            range={false}
            min={minValue}
            max={maxValue}
            marks={marks}
            value={values[0]}
            step={null}
            onChange={handleChange}
            tipFormatter={formatTip}
            {...props}
          />
        )}<RangeButton onClick={handleMaxValue}>{getButtonLabel(maxValue)}</RangeButton>
      </YearNavigationWrapper>
    );
  }
);

YearNavigation.displayName = "yearNavigation";

YearNavigation.propTypes = {
  data: PropTypes.array,
  marks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default YearNavigation;
