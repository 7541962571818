import { Chart, ChartSubtitle, ChartTitle, ResultsContainer } from "./styled";
import dayjs from "dayjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

function DefaultView() {

  const options = {
    scales: {
      y: {
        ticks: {
          color: "#000000",
          font: {
            weight: 700,
            size: 14,
          },
        },
        title: {
          display: true,
          text: "Value",
          font: {
            size: 20,
            weight: 700,
          },
          color: "#000000",
        },
      },
      x: {
        min: 0,
        max: 100,
        ticks: {
          color: "#000000",
          font: {
            weight: 700,
            size: 14,
          },
        },
        title: {
          display: true,
          text: "Year",
          font: {
            size: 20,
            weight: 700,
          },
          color: "#000000",
        },
      },
    }
  };
  const labels = [dayjs().format("YYYY")];
  const data = {
    labels: labels,
    datasets: [{
      label: 'My First Dataset',
      data: [0],
    }]
  };

  return (
    <ResultsContainer>
      <Chart>
      <ChartTitle>Data Analysis</ChartTitle>
      <ChartSubtitle>Choose a case study or configure a custom dataset by selecting a dataset and countries to compare.</ChartSubtitle>
      <Bar options={options} data={data} />
      </Chart>
    </ResultsContainer>
  );
}

export default DefaultView;
