import React, { useEffect, useState, useRef } from "react";
import chroma from "chroma-js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  ChartSource,
  ChartFooter,
  ChartFooterData,
  ChartWrapper,
} from "./styled";
import Stats from "./Stats";
import ChartDescription from "./ChartDescription";
import ChartLegend from "./ChartLegend";
import YearNavigation from "./YearNavigation";
import {
  buildYearOptions,
  isBetween,
  reverse,
  filterDatasetData,
  getAxisTitle,
  findValueByCountryAndYear,
  getStepSize,
  collator,
} from "./utils";
import { chartModes, tabViews, themeColors } from "./constants";
import { Corsair } from "./chartPlugins";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement
);

function BarChart({
  data,
  mode,
  onMinYearSelection,
  onMaxYearSelection,
  displayIndexValues,
  chartMode = chartModes[1],
  ...props
}) {
  const [charts, setCharts] = useState([]);
  const [yearValues, setYearValues] = useState([0, 0]);
  const [yearOptions, setYearOptions] = useState([]);
  const chartRef = useRef(null);

  const handleYearSelection = (values) => {
    setYearValues([values[0], values[1]]);
  };

  useEffect(() => {
    onMinYearSelection(yearValues[0]);
    onMaxYearSelection(yearValues[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearValues]);

  const values = filterDatasetData(data.data, data.countries);
  const exhibit = data.exhibit;
  const minYear = exhibit?.start;
  const maxYear = exhibit?.end;
  
  const datasetLabels = data.datasets?.map((x) => x.label);
  const datasets = data.datasets.sort((a, b) => {
    const datasetA = exhibit?.datasets.find((x) => {
      return x.dataset === a.value;
    });
    const datasetB = exhibit?.datasets.find((x) => {
      return x.dataset === b.value;
    });
    return collator.compare(datasetA.axis, datasetB.axis);
  });

  let countries;
  if(exhibit?.id === "A6"){
    const datasetCountries = {};
      data.data.forEach((item) => {
        if(item.year === minYear || item.year === maxYear){
          datasetCountries[item.country] = (datasetCountries[item.country] || 0) + 1;
        }
      });
      countries = data.countries.filter((country) => {
        return datasetCountries[country.label] === 4;
      }).map((country) => country.label);
  } else {
    countries = data.countries?.map((x) => x.label);
  }
  countries = [...new Set(countries)];

  let colorRangeSize;
  if (countries.length === 1) {
    colorRangeSize = datasetLabels.length;
  } else {
    colorRangeSize = countries.length;
  }

  const colors = [themeColors.PRIMARY_COLOR, themeColors.SECONDARY_COLOR];
  const colorRange = chroma
    .scale(["#FE7E6D", "#C79F38", "#409D44", "#7F8C9F"])
    .mode("lch")
    .colors(colorRangeSize);

  useEffect(() => {
    if (!data.datasets) return;
    const overlapOnly = exhibit && exhibit?.id !== "CUSTOM" && exhibit.id !== "A6";
    const yearOptions = buildYearOptions(data.data, countries, overlapOnly, minYear, maxYear);
    console.log(yearOptions);
    setYearOptions(yearOptions);
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (chartMode.value === "rank" || exhibit) {
      setYearValues([yearOptions[yearOptions.length - 1]?.value, yearOptions[yearOptions.length - 1]?.value]);
    } else {
      setYearValues([yearOptions[0]?.value, yearOptions[yearOptions.length - 1]?.value]);
    }
  }, [chartMode, yearOptions]); // eslint-disable-line

  useEffect(() => {
    if (yearValues) {
      generateCharts();
    }
  }, [chartMode, yearValues]); // eslint-disable-line

  // useEffect(() => {
  //   if (exhibit) {
  //     setChartMode(chartModes[1]);
  //   }
  // }, [mode]); // eslint-disable-line

  const generateCharts = () => {
    let years;
    let newCharts = [];
    years = yearOptions
      .filter((year) => {
        return isBetween(yearValues[0], yearValues[1], year.label);
      })
      .map((year) => {
        return year.label;
      });

    let options = {
      indexAxis: chartMode.value === "rank" ? "y" : "x",
      responsive: true,
      minBarLength: 5,
      plugins: {
        corsair: {
          color: "#888",
        },
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          wrap: true,
          font: {
            size: 20,
            weight: 700,
          },
          color: "#000000",
        },
        datalabels: {
          anchor: "end",
          align: "start",
          clip: true,
          padding: {
            right: 2,
          },
          labels: {
            padding: { top: 2 },
            title: {
              display: "auto",
              font: {
                weight: "bold",
                size: 20,
              },
              color: "white",
            },
            value: {
              display: true,
              font: {
                weight: "bold",
                size: 12,
              },
              color: "white",
            },
          },
          formatter: function (value) {
            const yearCount =
              parseFloat(yearValues[0]) - parseFloat(yearValues[1]);
            if (chartMode.value === "rank" && exhibit && yearCount <= 5) {
              return `${value?.label} - #${value?.rank}`;
            } else if (chartMode.value === "rank") {
              return `${value?.label} - #${value?.rank}`;
            } else {
              return null;
            }
          },
          display: function (context) {
            return false;
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.raw.label;
              if (exhibit) {
                label += " -  Value: " + context.raw.rawValue.toLocaleString();
              } else if (chartMode.value === "rank") {
                label +=
                  " -  Rank: " +
                  context.raw.rank +
                  ", Value: " +
                  context.raw.rawValue.toLocaleString();
              } else {
                label +=
                  " -  Value: " +
                  context.raw.rawValue.toLocaleString() +
                  ", Rank: " +
                  context.raw.rank;
              }
              return label;
            },
          },
          titleFont: {
            size: 16,
            weight: 700,
          },
          bodyFont: {
            size: 13,
            weight: 700,
          },
          padding: 10,
          boxPadding: 6,
          cornerRadius: 2,
          borderColor: "#ddd",
          borderWidth: 1,
          titleColor: "#000000",
          titleMarginBottom: 10,
          bodyColor: "#000000",
          bodySpacing: 8,
          backgroundColor: "rgba(255,255,255,1)",
        },
      },
      scales: {
        y: {
          reverse: false,
          barThickness: 10,
          maxBarThickness: 10,
          ticks: {
            color: "#000000",
            font: {
              weight: 700,
              size: 12,
            },
          },
          title: {
            display: true,
            text:
              mode === tabViews.FREE_FORM
                ? getAxisTitle(exhibit, datasets, "y")
                : exhibit.yAxisLabel,
            font: {
              size: 14,
              weight: 700,
            },
            color: "#000000",
          },
        },
        y1: {
          position: "right",
          display: false,
          ticks: {
            color: colors[1],
            font: {
              weight: 700,
              size: 12,
            },
          },
          title: {
            display: true,
            text:
              mode === tabViews.FREE_FORM
                ? getAxisTitle(exhibit, datasets, "y1")
                : exhibit.y1AxisLabel,
            font: {
              size: 14,
              weight: 700,
            },
            color: colors[1],
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        x: {
          reverse: false,
          min: chartMode.value === "rank" ? 825 : null,
          max: chartMode.value === "rank" ? 999 : null,
          ticks: {
            color: "#000000",
            font: {
              weight: 700,
              size: 12,
            },
          },
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: chartMode.value === "rank" ? "Rank" : "Year",
            font: {
              size: 14,
              weight: 700,
            },
            color: "#000000",
          },
        },
      },
    };

    if (chartMode.value === "rank") {
      options.scales.x.ticks.callback = function (dataLabel, index) {
        return reverse(dataLabel);
      };
    }

    let minValue;
    let maxValue;

    if (exhibit) {
      const groupedData = datasets.flatMap((dataset, idx) => {
        const exhibitDataset = exhibit?.datasets.find((x) => {
          return x.dataset === dataset.value;
        });
        const axis = exhibitDataset?.axis || "y";
        const colorIndex = axis === "y" ? 0 : 1;
        const data = countries.flatMap((country) => {
          const index = values.findIndex((item) => {
            return (
              item.year === years[0] &&
              item.country === country &&
              item.dataset_id === dataset.value
            );
          });

          if (index >= 0) {
            const value = values[index]["value"];

            if (value > maxValue || !maxValue) {
              maxValue = value;
            }

            if (value < minValue || !minValue) {
              minValue = value;
            }

            return [
              {
                x: country,
                y: value,
                label: dataset.label,
                rank: parseFloat(values[index]["rank_value"]),
                rawValue: parseFloat(values[index]["value"]),
              },
            ];
          } else {
            return [];
          }
        });

        if (data.length > 0) {
          return [
            {
              label: dataset.label,
              valueOrder: dataset.valueOrder,
              description: dataset.description,
              data,
              index: idx + 1,
              maxBarThickness: 40,
              backgroundColor: colors[colorIndex],
              borderColor: colors[colorIndex],
              legendColor: colors[colorIndex],
              yAxisID: axis,
            },
          ];
        } else {
          return [];
        }
      });

      options.scales.x.ticks.autoSkip = false;
      options.scales.y.ticks.color = colors[0];
      options.scales.y.title.color = colors[0];
      options.scales.y1.display = true;
      options.scales.x.title.text = "Country";

      options.scales.y.ticks.stepSize = getStepSize(minValue, maxValue);

      const chart = {
        datasets: {
          labels: countries,
          datasets: groupedData,
        },
        descriptions: datasets.map((dataset) => dataset.description),
        options,
      };

      newCharts.push(chart);
    } else {
      //Loop through each Dataset
      datasets.forEach((dataset, idx) => {
        //Find unique countries with data for the selected year(s)
        const groupedData = countries
          .flatMap((country, ydx) => {
            const data = years.flatMap((year) => {
              const value = findValueByCountryAndYear(
                values,
                country,
                year,
                dataset.value
              );
              if (value) {
                const chartValue =
                  chartMode.value === "rank"
                    ? reverse(parseFloat(value.rank_value))
                    : parseFloat(value.value);

                if (chartValue > maxValue || !maxValue) {
                  maxValue = chartValue;
                }

                if (chartValue < minValue || !minValue) {
                  minValue = chartValue;
                }

                return [
                  {
                    x: chartMode.value === "rank" ? chartValue : year,
                    y: chartMode.value === "rank" ? year : chartValue,
                    label: country,
                    rank: parseFloat(value.rank_value),
                    rawValue: parseFloat(value.value),
                  },
                ];
              } else {
                return [];
              }
            });

            const colorIdx = datasetLabels.length === 1 ? ydx : idx;
            if (data.length > 0) {
              return [
                {
                  label: country,
                  valueOrder: dataset.valueOrder,
                  //description: dataset.description,
                  data,
                  maxBarThickness: 40,
                  backgroundColor: colorRange[colorIdx],
                  borderColor: colorRange[colorIdx],
                  legendColor: colorRange[colorIdx],
                },
              ];
            } else {
              return [];
            }
          })
          .sort((a, b) => {
            return b.data[0].x - a.data[0].x;
          });

        options.scales.y.title.text =
          chartMode.value === "rank" ? "Year" : data.valueLabel || "Value";

        options.scales.y.ticks.stepSize = getStepSize(minValue, maxValue);

        const chart = {
          datasets: {
            labels: years,
            datasets: groupedData,
          },
          title: dataset.label,
          //descriptions: datasets.map((dataset) => dataset.description),
          options,
        };

        newCharts.push(chart);
      });
    }

    setCharts(newCharts);
  };

  // if (
  //   !data.datasets ||
  //   data.datasets.length === 0 ||
  //   data.countries.length === 0
  // )
  //   return;

  return (
    <>
      {charts.map((x, idx) => (
        <>
          <ChartWrapper>
            <Stats
              data={data}
              correlation={true}
              showTitle={false}
              selectedMinYear={yearValues[0]}
              selectedMaxYear={yearValues[1]}
            ></Stats>
            <Bar
              ref={chartRef}
              options={x.options}
              data={x.datasets}
              plugins={[
                ChartDataLabels,
                Title,
                Tooltip,
                Corsair
              ]}
            />
          </ChartWrapper>
          <ChartFooter>
            <YearNavigation
              onChange={handleYearSelection}
              value={yearValues}
              min={yearOptions[0]?.value}
              max={yearOptions[yearOptions.length - 1]?.value}
              range={chartMode.value !== "rank" && !exhibit}
              yearOptions={yearOptions}
            />
            <ChartFooterData>
              <ChartLegend chart={x} data={x.datasets} mode={mode} />
              {exhibit && (
                <ChartDescription
                  chart={x}
                  datasets={datasets}
                  mode={mode}
                  isExhibit={exhibit}
                />
              )}
              {data.source && (
                <ChartSource
                  dangerouslySetInnerHTML={{
                    __html: `<b>Sources</b>: ${data.source}`,
                  }}
                />
              )}
            </ChartFooterData>
          </ChartFooter>
        </>
      ))}
    </>
  );
}

export default BarChart;
