import dayjs from "dayjs";

export const buildCaseStudies = (caseStudies) => {
  return caseStudies.map((x, idx) => {
    return {
      label: x.title,
      value: idx,
      data: x,
    };
  });
};

export const buildExhibits = (exhibits) => {
  return exhibits.map((x) => {
    return {
      label: `Exhibit ${x.id} - ${x.title}`,
      value: x.id,
      data: x,
    };
  });
};

export const filterDatasetData = (datasetData, countries) => {
  const countryCodes = countries.map((country) => {
    return country.value;
  });
  return datasetData?.filter((dataset) => {
    //TODO Remove Null case, fix data
    return countryCodes.includes(dataset.country_code) || dataset.country_code === null;
  })
}

export const buildExhibitsFromDataset = (datasets, selectedDatasetData, countryData) => {
  let countries = countryData?.map((x) => {
    return x.label;
  });
  countries = [...new Set(countries)];

  const yearOptions = buildYearOptions(selectedDatasetData, countries);
  return {
    id: "CUSTOM",
    title:
      `${datasets[0].label}, ${datasets[1]?.label}`,
    datasets: [
      {
        dataset: datasets[0].value,
        axis: "y",
      },
      {
        dataset: datasets[1]?.value,
        axis: "y1",
      },
    ],
    start: yearOptions[0]?.value,
    end: yearOptions[yearOptions.length - 1]?.value,
    defaultChartView: null,
    supportedChartViews: ["Bar", "Line"],
    yAxisLabel: datasets[0].valueLabel,
    y1AxisLabel: datasets[1].valueLabel
  };
};

export const buildExhibitArticles = (exhibit) => {
  const articles = [];
  exhibit.data.article1.title && articles.push(exhibit.data.article1);
  exhibit.data.article2.title && articles.push(exhibit.data.article2);
  exhibit.data.article3.title && articles.push(exhibit.data.article3);
  return articles;
};

export const buildTags = (datasets) => {
  const tags = [];
  datasets.data.forEach((dataset) => {
    const datasetTags = dataset.tags?.split(',');
    datasetTags?.forEach((tag)=>{
      if(!tags.includes(tag)){
        tags.push({
          label: tag,
          data: tag});
      }
    });
  });

  return tags;
}

export const buildCategories = (categories) => {
  // const parentCategories = categories.filter((x) => {
  //   return parseInt(x.parent_id) === 0;
  // });

  return categories.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });
};

export const buildCountries = (countries) => {
  //De-duplicate
  const uniqueCountries = [
    ...new Map(countries.map((item) => [item["name"], item])).values(),
  ];

  return uniqueCountries.map((x) => {
    return {
      label: x.name,
      value: x.code,
    };
  });
};

export const buildDatasets = (datasets) => {
  return datasets.map((x) => {
    return {
      label: x.title,
      value: x.dataset_id,
      description: x.description,
      valueOrder: x.value_order,
      valueLabel: x.value_label,
      source: x.source,
      articles: JSON.parse(x.articles),
      tags: x.tags
    };
  }).sort((a, b) => a.label.localeCompare(b.label));
};

export const buildDatasetGroups = (datasets, categories, group = false, tags) => {
  if(group){
    const allCategories = categories.map((category) => {
      return { ...category, data: [] };
    });

    datasets.forEach((dataset) => {
      const categories = dataset.categories?.split(",");
  
      categories?.forEach((x) => {
        const category = allCategories.find((y) => y.label === x);
        category && category.data.push(dataset);
      });
    });

    return allCategories.map((x) => {
      return {
        label: x.label,
        options: buildDatasets(x.data),
      };
    });
  } else {
    const allCategories = categories.map((category) => {
      return category.label;
    });

    const filteredDatasets = datasets.filter((dataset) => {
      const categories = dataset.categories?.split(",");
      return categories?.some((x) => {
        return allCategories.some((y) => y === x);
      });
    });

    return buildDatasets(filteredDatasets);
  }
};

export const buildYears = (years) => {
  return years.map((x) => {
    return {
      label: x.year,
      value: x.year,
    };
  });
};

export const filterDatasets = (datasets, exhibitDatasets) => {
  const filteredDatasets = datasets.filter((x) => {
    return exhibitDatasets.includes(x.dataset_id);
  });

  return buildDatasets(filteredDatasets);
};

export const filterCountries = (datasets, countries) => {
  //TODO: Find countries that exist in all unique datasets
  // const groupedDatasets = datasets.reduce((map, e) => ({
  //   ...map,
  //   [e.dataset_id]: [...(map[e.dataset_id] ?? []), e]
  // }), {});
  
  const uniqueDatasetCountries = [...new Set(datasets.map((x) => x.country))];
  const uniqueCountries = [
    ...new Map(countries.map((item) => [item["name"], item])).values(),
  ];
  const filteredCountries = uniqueCountries.filter((x) => {
    return uniqueDatasetCountries.includes(x.name);
  });

  if (filteredCountries.length === 0) {
    filteredCountries.push({
      code: "USA",
      id: "2521",
      name: "United States of America",
    });
  }

  return buildCountries(filteredCountries);
};

export const isBetween = (startDate, endDate, date) => {
  const startDateInt = startDate;
  const endDateInt = endDate;
  const dateInt = date;

  return (
    (dayjs(dateInt).isAfter(dayjs(startDateInt)) &&
      dayjs(dateInt).isBefore(dayjs(endDateInt))) ||
    dayjs(dateInt).isSame(dayjs(startDateInt)) ||
    dayjs(dateInt).isSame(dayjs(endDateInt))
  );
};

export const reverse = (value, max = 999, min = 1) => {
  return max + min - value;
};

export const formatDate = (date) => {
  const format = String(date)?.includes("-") ? "YYYY-M-D" : "YYYY";
  return dayjs(String(date)).format(format);
};

export const buildYearOptions = (data, countries, overlapOnly = false, minYear, maxYear) => {
  let yearOptions = [];
  let yearLabels = [];

  data?.forEach((x) => {
    const year = x.year || x.date;
   
    let country = x.country;
    //TODO: Fix source data
    if (!x.country && overlapOnly) {
      country = "United States of America";
    }

    if (countries.includes(country)) {
      const includeYear = minYear && maxYear ? isBetween(minYear, maxYear, year) : true;
      if(!yearLabels.includes(year) && includeYear){
        yearLabels.push(year);
        yearOptions.push({
          label: year,
          value: year,
          countryCount: 1
        });
      } else {
        const yearOption = yearOptions.find((option) => {
          return option.label === year;
        });
        if(yearOption){
          yearOption.countryCount++;
        }
      }
    }
  });

  if(overlapOnly && countries.length > 1){
    yearOptions = yearOptions.filter((option) => {
      return option.countryCount === countries.length * 2;
    });
  }

  return yearOptions.sort((a, b) => {
    return dayjs(a.value) - dayjs(b.value);
  });
};

export const getDefaultDatasets = (datasets, ids = ["P363"]) => {
  const defaultDatasets = datasets.filter((dataset) => {
    return ids.includes(dataset.dataset_id);
  });

  return buildDatasets(defaultDatasets);
};

export const getDefaultCountries = (countries, ids) => {
  if(ids){
    const matches = countries?.filter((country) => {
      return ids.includes(country.value);
    });

    if(matches.length){
      return matches;
    } else {
      return getDefaultCountry(countries);
    }
  } else {
    return getDefaultCountry(countries);
  }
};

export const getDefaultCountry = (countryOptions) => {
  const usa = countryOptions?.filter((x) => {
    return x.value === "USA" && x.label === "United States of America";
  });
  return usa?.length > 0 ? usa : [getRandomCountry(countryOptions)]
}

export const getRandomCountry = (countries) => {
  if(countries?.length){
    const randomIndex = Math.floor(Math.random() * (countries?.length - 0)) + 0;
    return countries[randomIndex];
  } else {
    return null;
  }
};

export const matchCountries = (selectedCountries, countryOptions) => {
  return selectedCountries.filter((country) => {
    const test = countryOptions.filter((x) => {
      return x.label === country.label;
    });

    return test.length;
  });
};


export const getStatsData = (data, minYear, maxYear) => {
  let statsData = [];
  if(!data?.data){ 
    return [];
  }

  const values = filterDatasetData(data.data, data.countries);

  let countries = data.countries?.map((x) => x.label);
  countries = [...new Set(countries)];
  const yearOptions = buildYearOptions(data.data, countries);
  const yearCounts = {};
  const years = yearOptions.map((yearOption) => {
    yearCounts[yearOption.value] = 0;
    return yearOption.value;
  });

  statsData = data.datasets
    .map((dataset) => {
      const data = [];
      values.forEach((value) => {
        if (
          value.dataset_id === dataset.value &&
          countries.includes(value.country) &&
          years.includes(value.year)
        ) {
          data.push(value);
          yearCounts[value.year]++;
        }
      });
      return {
        ...dataset,
        data,
      };
    })
    .map((dataset) => {
      const filteredData = dataset.data.filter((value) => {
        //Filter years that have data for all countries
        return (
          yearCounts[value.year] === data.datasets.length * countries.length
        );
      });

      return {
        ...dataset,
        data: filteredData,
      };
    });

  let allValues = [];
  statsData.forEach((dataset) => {
    dataset.data.forEach((item) => {
      allValues.push(item.value);
    });
  });

  // const allValues = statsData
  // ? statsData.map((dataSet) => {
  //     return dataSet.data.map((item) => {
  //       return item.value;
  //     });
  //   })
  // : null;

  const datasetOneValues = statsData[0]
    ? statsData[0]?.data.map((item) => item.value)
    : null;
  const datasetTwoValues = statsData[1]
    ? statsData[1]?.data.map((item) => item.value)
    : null;

  return [datasetOneValues, datasetTwoValues]
}

export const buildStatsData = (values, dataset, country, minYear, maxYear, yearOptions, overlapOnly = false) => {
  let statsData = [];
  if(!values){ 
    return [];
  }

  const includeYear = (year) => {
    if(overlapOnly){
      return yearOptions.some((option) => {
        return option.countryCount > 1 && parseInt(option.value) === parseInt(year)
      });
    } else {
      return true;
    }
  }

  values.forEach((value) => {
    if (
      country === value.country_code &&
      value.dataset_id === dataset &&
      isBetween(minYear, maxYear, value.year) &&
      includeYear(value.year)
    ) {
     
      statsData.push(value.value);
    }
  });

  return statsData;
}

export const getAxisTitle = (exhibit, datasets, axis) => {
  if(datasets.length === 1){
    return "";
  }
  if(axis === "y1"){
    // if(datasets[1]?.label.toLowerCase().includes(exhibit?.y1AxisLabel.toLowerCase())){
       return [exhibit.y1AxisLabel];
    // } else {
    //  return exhibit?.y1AxisLabel ? [datasets[1].label, exhibit.y1AxisLabel] : "Value";
    //}
  } else {
    // if(datasets[0]?.label.toLowerCase().includes(exhibit?.yAxisLabel.toLowerCase())){
       return [exhibit.yAxisLabel];
    // } else {
    //  return exhibit?.yAxisLabel ? [datasets[0].label, exhibit.yAxisLabel] : "Value";
    //}
  }
}

export const getStepSize = (minValue, maxValue) => {
  return maxValue - minValue <= 1 ? 0.1 : undefined;
}

export const formatStatsValue = (number, locale = 'en-US') => {
  if (Number.isInteger(number)) {
      return new Intl.NumberFormat(locale).format(number);
  } else {
      return new Intl.NumberFormat(locale, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
      }).format(number);
  }
}

export const findValueByCountryAndYear = (values, country, year, datasetId) => {
  //Check to see if all values do not have a country. If so, we'll assume all values in the dataset are for USA
  const hasCountryValues = values.some((item) => {
    return item.country_code;
  });
  return values.find((item) => {
    let itemCountry;
    if(hasCountryValues){
      itemCountry = item.country;
    } else {
      itemCountry = item.country || "United States of America"
    }
    return (
      itemCountry === country &&
      (item.year === year || item.date === year) &&
      item.dataset_id === datasetId
    );
  });
}

export const getMinSharedYear = (data) => {
  // Extract 'year' values
  const years1 = data[0].data.map(item => item.x);
  const years2 = data[1].data.map(item => item.x);
  
  // Find intersection of 'year' values
  const intersection = years1.filter(year => years2.includes(year));
  
  // Get the lowest shared value of 'year'
  const lowestSharedYear = Math.min(...intersection);

  return lowestSharedYear;
}

export const collator = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
});

export const sortDatasets = (datasets, exhibit) => {
  return datasets.sort((a, b) => {
    const datasetA = exhibit?.datasets.find((x) => {
      return x.dataset === a.value;
    });
    const datasetB = exhibit?.datasets.find((x) => {
      return x.dataset === b.value;
    });
    return collator.compare(datasetA.axis, datasetB.axis);
  });
}


export const getShareURL = () => {
  const location = document.referrer.split("?")[0];
  return `${location}data-analysis/${window.location.search}`;
}