import { DescriptionList, Description, Descriptions } from "./styled";

function ChartDescription({ mode, isExhibit, chart, datasets, ...props }) {
  const descriptions = (chart) => {
    if (isExhibit) {
      return chart.descriptions?.map((x, idx) => (
        <Description
          key={`${idx}-chart-description`}
          isExhibit={isExhibit}
          dangerouslySetInnerHTML={{ __html: `${x}` }}
        />
      ));
    } else {
      return datasets
        .flatMap((x, idx) => {
          return x.description ? [x.description] : [];
        })
        .map((x, idx) => (
          <Description
            key={`${idx}-chart-description`}
            isExhibit={isExhibit}
            dangerouslySetInnerHTML={{ __html: `${x}` }}
          />
        ));
    }
  };

  return (
    <>
      {isExhibit ? (
        <DescriptionList>{descriptions(chart)}</DescriptionList>
      ) : (
        <Descriptions>{descriptions(chart)}</Descriptions>
      )}
    </>
  );
}

export default ChartDescription;
