import { mean, median, corr, std } from "mathjs";
import {
  Stats as StatsStyled,
  StatGroup,
  TooltipContent,
  TooltipTrigger,
} from "./styled";
import { formatStatsValue } from "./utils";
import { Tooltip } from "./Tooltip";
import { filterDatasetData, buildStatsData, buildYearOptions } from "./utils";

function Stats({
  className,
  data,
  selectedMinYear,
  selectedMaxYear,
  selectedCountries,
  correlation,
  showTitle = true,
  ...props
}) {
  let countries = data.countries?.map((x) => x.label);
  countries = [...new Set(countries)];

  const values = filterDatasetData(data.data, data.countries);
  const yearOptions = buildYearOptions(data.data, countries);

  let datasetOneValues = [];
  let datasetTwoValues = [];

  data.datasets.forEach((dataset, idx) => {
    data.countries.forEach((country) => {
      const data = {
        label: country.label,
        values,
      };
    
      if (yearOptions?.[0]) {
        const minYear = selectedMinYear || yearOptions?.[0]?.value;
        const maxYear =
          selectedMaxYear || yearOptions?.[yearOptions.length - 1]?.value;

        data.values = buildStatsData(
          values,
          dataset.value,
          country.value,
          minYear,
          maxYear,
          yearOptions,
          correlation
        );
      } else {
        data.values = [];
      }

      if (idx === 1) {
        datasetTwoValues.push(data);
      } else {
        datasetOneValues.push(data);
      }
    });
  });

  const formatMean = (values) => {
    return values.length ? formatStatsValue(mean(values)) : "";
  };

  const minValue = (values) => {
    return formatStatsValue(Math.min(...values));
  };

  const maxValue = (values) => {
    return formatStatsValue(Math.max(...values));
  };

  const formatMedian = (values) => {
    return values.length ? formatStatsValue(median(values)) : "";
  };

  const formatStandardDeviation = (values) => {
    return values.length ? formatStatsValue(std(values)) : "";
  };

  const formatVariation = (values) => {
    return values.length > 0
      ? `${((std(values) / mean(values)) * 100).toFixed(2)}%`
      : "";
    //return values.length > 0 ? `${Math.round(variance(values)).toLocaleString()}%` : "";
  };

  const formatCorrelation = (datasetOne, datasetTwo) => {
    if (datasetOneValues?.[0]?.values.length === datasetTwoValues?.[0]?.values.length) {
      const correlation = corr(datasetOne, datasetTwo).toFixed(3);
      if (!isNaN(correlation)) {
        return correlation;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const countryStats = (country) => {
    return (
      <div className="country-stats">
        <h5>{country.label}</h5>
        <p>
          <strong>Min Value:</strong> {minValue(country.values)}
        </p>
        <p>
          <strong>Max Value:</strong> {maxValue(country.values)}
        </p>
        <p>
          <strong>Mean:</strong> {formatMean(country.values)}
        </p>
        <p>
          <strong>Median:</strong> {formatMedian(country.values)}
        </p>
        <p>
          <span>
            <strong>Coefficient of Variation:</strong>{" "}
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <img src="/info-icon.svg" alt="" />
                <span>View Definition</span>
              </TooltipTrigger>
              <TooltipContent className="Tooltip">
                Coefficient of Variation is defined as the ratio of the standard
                deviation to the mean.
              </TooltipContent>
            </Tooltip>
          </span>
          {formatVariation(country.values)}
        </p>
        <p>
          <span>
            <strong>Standard Deviation:</strong>{" "}
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <img src="/info-icon.svg" alt="" />
                <span>View Definition</span>
              </TooltipTrigger>
              <TooltipContent className="Tooltip">
                Standard Deviation is defined as the measure of how dispersed
                the data is in relation to the mean.
              </TooltipContent>
            </Tooltip>
          </span>
          {formatStandardDeviation(country.values)}
        </p>
      </div>
    );
  };

  return (
    <StatsStyled className={className} correlation={correlation}>
      {correlation ? (
        formatCorrelation(
          datasetOneValues?.[0]?.values,
          datasetTwoValues?.[0]?.values
        ) && (
          <StatGroup className="correlation">
            <strong>{`Correlation`}:</strong>
            {datasetOneValues &&
              datasetTwoValues &&
              formatCorrelation(
                datasetOneValues[0].values,
                datasetTwoValues[0].values
              )}
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <img src="/info-icon.svg" alt="" />
                <span>View Definition</span>
              </TooltipTrigger>
              <TooltipContent className="Tooltip">
                The strength of the linear relationship between the selected
                datasets for years of overlap.
              </TooltipContent>
            </Tooltip>
          </StatGroup>
        )
      ) : (
        <>
          {datasetOneValues?.length > 0 && (
            <StatGroup>
              <h4>Dataset A: {data.datasets[0].label}</h4>
              {datasetOneValues.map((country) => {
                return countryStats(country);
              })}
            </StatGroup>
          )}
          {datasetTwoValues?.length > 0 && (
            <StatGroup>
              <h4>Dataset B: {data.datasets[1].label}</h4>
              {datasetTwoValues.map((country) => countryStats(country))}
            </StatGroup>
          )}
        </>
      )}
    </StatsStyled>
  );
}

export default Stats;
